import React from 'react'

export default React.forwardRef((
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
  ref: React.ForwardedRef<HTMLButtonElement>
): JSX.Element => {
  return (
    <button {...props} ref={ref} />
  )
})
