import React from 'react'
import { Pdf } from './pdf'

export interface Props {
  pdf: Pdf

  // Starts with 1, not 0.
  pageNumber: number

  // 1.0 is the natural size.
  scale: number

  // Resolution (can render at a resolution higher than device pixel ratio).
  resolution?: number

  // Called whenever the PDF is rendered or re-rendered.
  onRender?: (x?: number, y?: number) => void
}

const PdfPagePreview: React.FC<Props> = (props) => {
  const { pdf, pageNumber, scale, resolution, onRender } = props

  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(
    () => {
      const container = ref.current
      if (container === null) {
        return
      }
      const canvas = document.createElement('canvas')
      container.innerHTML = ''
      container.replaceChildren(canvas)

      pdf.pdfjsDoc
        .getPage(pageNumber)
        .then(async (page) => {
          const pixelRatio = devicePixelRatio * (resolution ?? 1)
          const viewport = page.getViewport({ scale })
          canvas.width = Math.floor(viewport.width * pixelRatio)
          canvas.height = Math.floor(viewport.height * pixelRatio)
          canvas.style.width = `${viewport.width}px`
          canvas.style.height = `${viewport.height}px`
          const canvasContext = canvas.getContext('2d')
          if (canvasContext === null) {
            throw new Error('Unable to get canvas context')
          }
          canvasContext.scale(pixelRatio, pixelRatio)
          const renderTask = page.render({ canvasContext, viewport })
          await renderTask.promise
          onRender?.(ref.current?.offsetLeft, ref.current?.offsetTop)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    [pdf, scale]
  )

  return (
    <div ref={ref} className='inline-block' />
  )
}

export default PdfPagePreview
