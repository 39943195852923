import { animated, SpringValue, useSpring } from '@react-spring/web'
import React from 'react'

import { Point } from 'features/gesture/gesture'

import { GestureListener, StickerConfig, StickerState, Transform } from './sticker'

export interface Props<N, A> {
  clientOffset: Point
  paperOffset: Point
  paperScale: SpringValue<number>
  config: StickerConfig<A>
  sticker: StickerState<N, A>
  addGestureListener: (listener: GestureListener) => () => void
  onTransformChange: (transform: Transform) => void
  onActiveChange: (isActive: boolean) => void
}

export default <N, A> (props: Props<N, A>): JSX.Element => {
  const {
    clientOffset,
    paperOffset,
    paperScale,
    config,
    sticker,
    addGestureListener,
    onTransformChange,
    onActiveChange
  } = props
  const [spring, api] = useSpring(() => ({
    active: false,
    x: paperOffset.x,
    y: paperOffset.y,
    scale: sticker.transform.scale
  }))
  const ref = React.useRef<HTMLDivElement>(null)
  const start = React.useRef<Point & { scale: number }>({ x: 0, y: 0, scale: 1.0 })

  React.useEffect(
    () => {
      const unsubscribe = addGestureListener((gesture) => {
        if (ref.current === null) {
          return false
        }
        const rect = ref.current.getBoundingClientRect()
        switch (gesture.type) {
          case 'start': {
            spring.active.set(
              gesture.x >= rect.left - clientOffset.x &&
              gesture.x <= rect.right - clientOffset.x &&
              gesture.y >= rect.top - clientOffset.y &&
              gesture.y <= rect.bottom - clientOffset.y
            )
            start.current.x = spring.x.get()
            start.current.y = spring.y.get()
            start.current.scale = spring.scale.get()
            onActiveChange(spring.active.get())
            return spring.active.get()
          }
          case 'stop': {
            onActiveChange(false)
            spring.active.set(false)
            return true
          }
          case 'translate': {
            if (spring.active.get()) {
              const computed = {
                x: start.current.x + gesture.x / paperScale.get(),
                y: start.current.y + gesture.y / paperScale.get()
              }
              api.start({
                ...computed,
                immediate: true
              })
              onTransformChange({
                translate: computed,
                scale: spring.scale.get()
              })
              return true
            }
            return false
          }
          case 'scale': {
            if (spring.active.get()) {
              const computed = {
                x: start.current.x + gesture.x - rect.width / 2 * (gesture.factor - 1) / paperScale.get(),
                y: start.current.y + gesture.y - rect.height / 2 * (gesture.factor - 1) / paperScale.get(),
                scale: start.current.scale * gesture.factor
              }
              api.start({
                ...computed,
                immediate: true
              })
              onTransformChange({
                translate: {
                  x: computed.x,
                  y: computed.y
                },
                scale: computed.scale
              })
              return true
            }
            return false
          }
        }
      })
      return unsubscribe
    },
    []
  )

  return (
    <animated.div
      ref={ref}
      className='absolute origin-top-left'
      style={spring}
    >
      {config.render(sticker.id, spring.active, sticker.attributes)}
    </animated.div>
  )
}
