import { useLoaderData } from 'react-router-dom'

export function useTypedLoaderData<T> (typePredicate: (d: any) => d is T): T | null {
  const loaderData = useLoaderData()
  if (loaderData === null) {
    return null
  }
  if (!typePredicate(loaderData)) {
    throw new Error(`Unexpected type for loaded data: ${JSON.stringify(loaderData)}`)
  }
  return loaderData
}
