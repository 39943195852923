import { initializeApp } from 'firebase/app'

export function initializeFirebaseApp (): void {
  initializeApp({
    apiKey: 'AIzaSyAyOosisGsbzVOxH57-dany-Y9E0_kF6oM',
    authDomain: 'roger-origami.firebaseapp.com',
    projectId: 'roger-origami',
    storageBucket: 'roger-origami.appspot.com',
    messagingSenderId: '399450199545',
    appId: '1:399450199545:web:3d4b741af5f9c1848fd928'
  })
}
