import { H } from 'highlight.run'
import { isLiveChannel, isProduction, isStorybook } from 'util/env'

export function initializeHighlightAnalytics (): void {
  if (isProduction() && !isStorybook() && isLiveChannel()) {
    H.init('1epmj7en', {
      enableCanvasRecording: true,
      samplingStrategy: {
        canvas: 2,
        canvasQuality: 'low',
        canvasMaxSnapshotDimension: 640
      }
    })
  }
}
