// Toolbar at the bottom of the screen for adding/removing stickers.
//
// When a sticker is being manipulated, changes into a trash icon.

import { animated, FrameValue, useSpring } from '@react-spring/web'
import cx from 'classnames'
import React from 'react'

import IconButton from 'components/icon-button/IconButton'
import { Signature } from 'features/signaturedb/types'
import { StickerId } from 'features/sticker/sticker'

import AddSignatureTipPopover from './AddSignatureTipPopover'
import ChooseSignatureDialog from './ChooseSignatureDialog'
import CreateSignatureDialog from './CreateSignatureDialog'
import DeleteIcon from './DeleteIcon'
import TextEditorDialog, { Props as TextEditorDialogProps } from './TextEditorDialog'

export interface Props {
  activeStickerIdFrameValue: FrameValue<StickerId | null>
  isAddSignatureTipOpen: boolean
  onAddSignatureTipOpenChange: (open: boolean) => void
  signatureLoader: () => Signature[]
  onChooseSignature: (signature: Signature) => void
  onCreateSignature: (signature: Signature) => void
  onRemoveSticker: (id: StickerId) => void
  textEditorDialogState: StickerId | boolean
  onTextEditorDialogStateChange: (state: StickerId | boolean) => void
  onTextEditorDialogDone: TextEditorDialogProps['onDone']
  getStickerText: (id: StickerId) => string | null
}

export default (props: Props): JSX.Element => {
  const {
    activeStickerIdFrameValue,
    isAddSignatureTipOpen,
    onAddSignatureTipOpenChange,
    signatureLoader,
    onChooseSignature,
    onCreateSignature,
    onRemoveSticker,
    textEditorDialogState,
    onTextEditorDialogStateChange,
    onTextEditorDialogDone,
    getStickerText
  } = props

  const [aboutToDeleteSpring, aboutToDeleteApi] =
    useSpring<{ stickerId: StickerId | false }>(() => ({
      stickerId: false
    }))
  const [isCreateSignatureDialogOpen, setCreateSignatureDialogOpen] = React.useState(false)

  return (
    <div>
      <animated.div
        className={cx(
          'absolute cursor-auto bottom-[60px] h-0 inset-x-0',
          'flex justify-center transition space-x-1.5'
        )}
        style={{
          opacity: activeStickerIdFrameValue.to(
            (stickerId) => stickerId !== null ? 0 : 1
          ),
          y: activeStickerIdFrameValue.to(
            (stickerId) => stickerId !== null ? 8 : 0
          )
        }}
      >
        <div>
          <AddSignatureTipPopover
            open={isAddSignatureTipOpen}
            onOpenChange={onAddSignatureTipOpenChange}
            anchor={
              <ChooseSignatureDialog
                signatureLoader={signatureLoader}
                onCreate={() => {
                  setCreateSignatureDialogOpen(true)
                }}
                onChoose={onChooseSignature}
                trigger={
                  <div className='appearance-none'>
                    <IconButton
                      icon='signature'
                      style={isAddSignatureTipOpen ? 'loud' : undefined}
                      onClick={() => {
                        onAddSignatureTipOpenChange(false)
                      }}
                    />
                  </div>
                }
              />
            }
          />
          <CreateSignatureDialog
            open={isCreateSignatureDialogOpen}
            onOpenChange={setCreateSignatureDialogOpen}
            onCreate={(signature) => {
              setCreateSignatureDialogOpen(false)
              onCreateSignature(signature)
            }}
          />
        </div>
        <TextEditorDialog
          open={
            typeof textEditorDialogState === 'string'
              ? getStickerText(textEditorDialogState) ?? ''
              : textEditorDialogState
          }
          onOpenChange={onTextEditorDialogStateChange}
          trigger={
            <IconButton icon='text' />
          }
          onDone={onTextEditorDialogDone}
        />
      </animated.div>
      <animated.div
        className={cx(
          'absolute cursor-auto bottom-[60px] h-0 inset-x-0',
          'flex justify-center transition delay-500'
        )}
        style={{
          opacity: activeStickerIdFrameValue.to(
            (stickerId) => stickerId !== null ? 1 : 0
          ),
          y: activeStickerIdFrameValue.to(
            (stickerId) => stickerId !== null ? 0 : 8
          ),
          zIndex: activeStickerIdFrameValue.to(
            (stickerId) => stickerId !== null ? 0 : -1
          )
        }}
      >
        <div>
          <animated.div
            className='transition rounded-full'
            style={{
              scale: aboutToDeleteSpring.stickerId.to(
                (stickerId) => stickerId === false ? 1 : 3
              )
            }}
          >
            <DeleteIcon
              onDragEnter={() => {
                const stickerId = activeStickerIdFrameValue.get()
                aboutToDeleteApi.set({
                  stickerId: stickerId === null ? false : stickerId
                })
              }}
              onDragExit={() => {
                aboutToDeleteApi.set({
                  stickerId: false
                })
              }}
              onDragDrop={() => {
                const id = aboutToDeleteSpring.stickerId.get()
                if (id === false) {
                  return
                }
                onRemoveSticker(id)
              }}
            />
          </animated.div>
        </div>
      </animated.div>
    </div>
  )
}
