import { IsomorphicStorage, StorageLocation } from './storage'
import { getBytes, getStorage, ref, uploadBytes } from 'firebase/storage'
import { getApp } from 'firebase/app'

export class BrowserStorage implements IsomorphicStorage {
  getDefaultBucketName (): string {
    const { storageBucket } = getApp().options
    if (storageBucket === undefined) {
      throw new Error('Default storage not configured in Firebase config')
    }
    return storageBucket
  }

  async getObjectBytes (storageLocation: StorageLocation): Promise<Uint8Array> {
    const storage = getStorage(getApp(), `gs://${storageLocation.bucketName}`)
    const objectRef = ref(storage, storageLocation.objectName)
    const arrayBuffer = await getBytes(objectRef)
    return new Uint8Array(arrayBuffer)
  }

  async setObjectBytes (storageLocation: StorageLocation, bytes: Uint8Array): Promise<void> {
    const storage = getStorage(getApp(), `gs://${storageLocation.bucketName}`)
    const objectRef = ref(storage, storageLocation.objectName)
    await uploadBytes(objectRef, bytes)
  }
}
