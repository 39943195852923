import { getDocument, GlobalWorkerOptions, PDFDocumentProxy } from 'pdfjs-dist'
import React from 'react'

// This is some magic to load the required pdf.worker.js file from a CDN.
// Ideally, we'd load this from a packaged/delivered place that we distribute
// and control ourselves.
//
// Since we're doing it this way for now, we need to make sure that the version
// here matches the version of pdfjs-dist that we're using (2.14.305).
GlobalWorkerOptions.workerSrc =
  'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.js'

export async function loadPdf (data: Uint8Array): Promise<PDFDocumentProxy> {
  return await getDocument(data).promise
}

export function usePdfJsDoc (data: Uint8Array | undefined): undefined | null | PDFDocumentProxy {
  const [doc, setDoc] = React.useState<PDFDocumentProxy | null>()

  React.useEffect(
    () => {
      if (data === undefined) {
        return
      }

      loadPdf(data)
        .then(setDoc)
        .catch((err) => {
          console.log(err)
          setDoc(null)
        })

      return () => {
        setDoc(undefined)
      }
    },
    [data]
  )

  return doc
}
