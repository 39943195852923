import cx from 'classnames'
import CopyEmailAddressButton from 'components/copy-email-address-button/CopyEmailAddressButton'
import BackButton from 'components/white-card/BackButton'
import React from 'react'

export interface Props {
  onBack: () => void
}

export default (props: Props): JSX.Element => {
  const { onBack } = props

  return (
    <div className='relative flex flex-col h-full justify-between'>
      <div className='absolute top-2 left-2'>
        <BackButton
          onClick={onBack}
        />
      </div>
      <div className='space-y-2 px-4 text-center'>
        <div
          className={cx(
            'py-4 px-10 text-center font-bold font-mono text-grass-light-12 leading-[1.5]'
          )}
        >
          Send your PDF attachment to <a href='mailto:free@docs.thanksroger.com' className='text-origami-grass-light-11 underline underline-offset-[3px]'>free@docs.thanksroger.com</a>
        </div>
        <div className='text-xs tracking-[1.25] text-origami-grass-light-11'>
          It's private and secure. We'll reply with a link in 15 seconds that'll
          let you stick your signature on your doc.
        </div>
      </div>
      <div className='p-2'>
        <CopyEmailAddressButton />
      </div>
    </div>
  )
}
