import React from 'react'

import Button from 'components/button/Button'

import backIconUrl from './back.svg'

export default ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <Button
      className='w-10 h-10 flex items-center justify-center'
      onClick={onClick}
    >
      <img src={backIconUrl} className='pointer-events-none' />
    </Button>
  )
}
