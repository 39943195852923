import cx from 'classnames'
import OrigamiButton from 'components/origami-button/OrigamiButton'
import BackButton from 'components/white-card/BackButton'
import React from 'react'

export interface Props {
  onBack: () => void
  onUpload: (file: File) => void
}

export default (props: Props): JSX.Element => {
  const { onBack, onUpload } = props
  const [isNotPdfError, setNotPdfError] = React.useState(false)

  return (
    <div className='relative flex flex-col h-full justify-between'>
      <div className='absolute top-2 left-2'>
        <BackButton onClick={onBack} />
      </div>
      <div className='space-y-2'>
        <div
          className={cx(
            'py-4 px-10 text-center font-bold font-mono text-grass-light-12 leading-[1.5]',
            'whitespace-nowrap'
          )}
        >
          Upload a PDF file
        </div>
        <div
          className={cx(
            'italic font-mono font-[450] leading-[1.3] text-origami-sage-light-11 px-4'
          )}
        >
          You may select a PDF file stored on this device. You'll stick your signature on it, next.
        </div>
        {
          isNotPdfError && (
            <div
              className={cx(
                'italic font-mono font-[450] leading-[1.3] text-origami-sage-light-12 px-4',
                'pt-4'
              )}
            >
              The file you chose was not a PDF. Please try again.
            </div>
          )
        }
      </div>
      <div className='p-2'>
        <label htmlFor='upload-input' className='cursor-pointer rounded-2xl'>
          <div className='pointer-events-none'>
            <OrigamiButton
              label='Select a PDF file'
              fullWidth
              style='primary'
            />
          </div>
        </label>
        <input
          className='opacity-0 h-0 absolute'
          id='upload-input'
          type='file'
          accept='.pdf'
          onChange={(event) => {
            setNotPdfError(false)
            const { files } = event.target
            if (files === null || files.length !== 1) {
              return
            }
            const file = files[0]
            if (file.type !== 'application/pdf') {
              setNotPdfError(true)
              return
            }
            onUpload(file)
          }}
        />
      </div>
    </div>
  )
}
