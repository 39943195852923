import React from 'react'

export interface Props {
  children: React.ReactNode
}

export default (props: Props): JSX.Element => {
  const { children } = props
  return (
    <div className='font-mono italic font-[450] leading-[1.3] text-origami-grass-light-12'>
      {children}
    </div>
  )
}
