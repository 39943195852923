import cx from 'classnames'
import React from 'react'

export type Level = 1

export interface Props {
  level: Level
  children: React.ReactNode
}

export default (props: Props): JSX.Element => {
  const { level, children } = props

  const classNames: Record<Level, string> = {
    1: cx(
      'font-extrabold uppercase text-[36px] leading-[1.1] tracking-[-0.002em]',
      'text-origami-gray-dark'
    )
  }

  return (
    React.createElement(
      `h${level}`,
      { className: classNames[level] },
      children
    )
  )
}
