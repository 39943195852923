import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { ErrorBoundary } from '@highlight-run/react'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { initializeGoogleAnalytics } from 'features/analytics/google'
import { initializeHighlightAnalytics } from 'features/analytics/highlight'
import { initializeGoogleOptimize } from 'features/optimize'
import { initializeFirebaseApp } from 'features/firebase'

import '../index.css'
import App from './App'

addEventListener('load', () => {
  // Firebase
  initializeFirebaseApp()

  // Analytics
  initializeHighlightAnalytics()
  initializeGoogleAnalytics()

  // Experimentation
  initializeGoogleOptimize()

  // Application
  initializeReactApp(document.body)
})

function initializeReactApp (container: HTMLElement): void {
  // Create a <div> and add it to the container.
  const element = document.createElement('div')
  container.appendChild(element)

  // Create a React root and render the app.
  const root = createRoot(element)
  root.render(
    <React.StrictMode>
      <ErrorBoundary showDialog>
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  )
}
