import React from 'react'
import cx from 'classnames'

export default (): JSX.Element => {
  return (
    <div
      className={cx(
        'rounded bg-origami-grass-light-3 border border-origami-grass-light-11',
        'font-mono font-bold text-sm leading-[1.4] px-4 pt-3 pb-2.5',
        'text-origami-grass-light-12 inline-block'
      )}
    >
      Add your signature
    </div>
  )
}
