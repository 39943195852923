import load from 'load-script'

const apiKey = 'AIzaSyAyOosisGsbzVOxH57-dany-Y9E0_kF6oM'
const clientId = '399450199545-hfi02dqj8qtcnpnsdb7g52rejb902r1e.apps.googleusercontent.com'

export async function showPicker (): Promise<File> {
  const accessToken = await getAccessToken()
  await initializePicker()
  await initializeDrive()
  return await new Promise((resolve, reject) => {
    const picker = new google.picker.PickerBuilder()
      .addView(google.picker.ViewId.PDFS)
      .setAppId(clientId)
      .setDeveloperKey(apiKey)
      .setOAuthToken(accessToken)
      .setCallback((result) => {
        if (result[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
          const doc = result[google.picker.Response.DOCUMENTS][0]
          const fileId = doc.id
          requestAnimationFrame(
            () => {
              gapi.client.drive.files
                .get({
                  fileId,
                  alt: 'media'
                })
                .then((response) => {
                  resolve(new File([strEncodeUTF8(response.body)], doc.name))
                })
                .catch(reject)
            }
          )
        }
      })
      .setSize(innerWidth - 2, innerHeight - 2)
      .build()
    picker.setVisible(true)
  })
}

//
// https://stackoverflow.com/questions/63813683/file-coming-back-as-string
//
function strEncodeUTF8 (str: string): Uint8Array {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return bufView
}

async function getAccessToken (): Promise<string> {
  await initializeGsi()
  return await new Promise((resolve) => {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      scope: 'https://www.googleapis.com/auth/drive.file',
      callback: (response) => {
        resolve(response.access_token)
      }
    })
    client.requestAccessToken()
  })
}

/**
 * google.accounts API calls may not happen until this promise is fulfilled.
 */
let gsiPromise: Promise<void> | null = null
async function initializeGsi (): Promise<void> {
  if (gsiPromise === null) {
    gsiPromise = new Promise((resolve, reject) => {
      load('https://accounts.google.com/gsi/client', { async: true }, (err) => {
        if (err !== null) {
          reject(err)
          return
        }
        resolve()
      })
    })
  }
  return await gsiPromise
}

/**
 * gapi.load API calls may not happen until this promise is fulfilled.
 */
let gapiPromise: Promise<void> | null = null
async function initializeGapi (): Promise<void> {
  if (gapiPromise === null) {
    gapiPromise = new Promise((resolve, reject) => {
      load('https://apis.google.com/js/api.js', { async: true }, (err) => {
        if (err !== null) {
          reject(err)
          return
        }
        resolve()
      })
    })
  }
  return await gapiPromise
}

/**
 * google.picker API calls may not happen until this promise is fulfilled.
 */
let pickerPromise: Promise<void> | null = null
async function initializePicker (): Promise<void> {
  if (pickerPromise === null) {
    await initializeGapi()
    pickerPromise = new Promise((resolve) => {
      gapi.load('picker', resolve)
    })
  }
  return await pickerPromise
}

/**
 * google.client API calls may not happen until this promise is fulfilled.
 */
let clientPromise: Promise<void> | null = null
async function initializeClient (): Promise<void> {
  if (clientPromise === null) {
    clientPromise = new Promise((resolve) => {
      gapi.load('client', resolve)
    })
  }
  return await clientPromise
}

/**
 * google.client.drive API calls may not happen until this promise is fulfilled.
 */
let drivePromise: Promise<void> | null = null
async function initializeDrive (): Promise<void> {
  if (drivePromise === null) {
    await initializeClient()
    drivePromise = gapi.client.init({
      apiKey,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']
    })
  }
  return await drivePromise
}
