import cx from 'classnames'
import Button from 'components/button/Button'
import React from 'react'
import { useAsync } from 'util/hooks'

export interface Props {
  icon: 'right-arrow' | 'signature' | 'text' | 'add' | 'close'
  style?: 'primary' | 'secondary' | 'subtle' | 'loud'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export default React.forwardRef((
  props: Props, ref: React.ForwardedRef<HTMLButtonElement>
): JSX.Element | null => {
  const { icon, style, onClick } = props

  const [iconUrl, loadIconUrl] = useAsync(
    async () => {
      const { default: url } = await import(`./${icon}.svg`)
      return url
    },
    [icon]
  )

  React.useEffect(loadIconUrl, [icon])

  if (iconUrl.status !== 'success') {
    return null
  }

  return (
    <Button
      ref={ref}
      className={cx(
        'rounded-full w-11 h-11 flex items-center justify-center',
        getStyleClass(style)
      )}
      onClick={onClick}
    >
      <img src={iconUrl.value} className='pointer-events-none' />
    </Button>
  )
})

function getStyleClass (style: Props['style']): string {
  switch (style) {
    case 'primary': {
      return 'bg-origami-sage-light-12'
    }
    case 'secondary': {
      return 'bg-origami-grass-light-4'
    }
    case 'subtle': {
      return 'bg-transparent'
    }
    case 'loud': {
      return 'bg-origami-grass-light-10'
    }
    default: {
      return 'bg-black/40 backdrop-blur'
    }
  }
}
