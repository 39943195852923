import { chunk, sumBy } from 'lodash'

import { Path } from 'features/draw/drawing'
import { Signature } from 'features/signaturedb/types'
import { smooth, toSvgPathDefinition } from 'features/smoothing/smoothing'

export function signatureToSvg (signature: Signature): File {
  const { drawing } = signature
  const pointSamplingRate = 3
  const smoothingFactor = 0.2

  const sampledPaths: Path[] = drawing.paths.map((path) => ({
    points: chunk(path.points, pointSamplingRate)
      .map((points) => ({
        x: sumBy(points, 'x') / points.length,
        y: sumBy(points, 'y') / points.length
      }))
  }))
  const smoothedPaths = sampledPaths.map((path) => smooth(path, { factor: smoothingFactor }))
  const { width, height } = signature.size

  const svg = `<?xml version="1.0" standalone="no"?>
<svg
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  width="${width}" height="${height}"
  viewBox="0 0 ${width} ${height}">
  <path
    d="${smoothedPaths.map(toSvgPathDefinition).join(' ')}"
    stroke="#3451b2"
    stroke-width="3"
    stroke-linecap="3"
    stroke-linejoin="3"
    fill="transparent"
  />
</svg>`

  return new File([svg], 'signature.svg', { type: 'image/svg+xml' })
}
