import cx from 'classnames'
import React from 'react'
import * as Popover from '@radix-ui/react-popover'

import AddSignatureTip from 'features/tips/AddSignatureTip'

export interface Props {
  open: Popover.PopoverProps['open']
  onOpenChange: Popover.PopoverProps['onOpenChange']
  anchor: Popover.PopoverAnchorProps['children']
}

export default (props: Props): JSX.Element => {
  const { open, onOpenChange, anchor } = props

  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      <Popover.Anchor asChild>
        {anchor}
      </Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          className='outline-none shadow-origami-sm'
          onInteractOutside={(event) => {
            event.preventDefault()
          }}
        >
          <AddSignatureTip />
          <Popover.Arrow
            className={cx(
              'stroke-origami-grass-light-11 fill-origami-grass-light-3',
              'stroke-[2.4] -translate-y-px',
              '[stroke-dasharray:0,30,36]'
            )}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
