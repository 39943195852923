import * as Dialog from '@radix-ui/react-dialog'
import OrigamiButton from 'components/origami-button/OrigamiButton'
import { Pdf, PdfPagePreview } from 'features/pdf'
import React from 'react'
import { useAsync } from 'util/hooks'

export type DownloadDialogState = Open | Closed

export interface Open {
  status: 'open'
  getSignedPdf: () => Promise<Pdf>
}

export interface Closed {
  status: 'closed'
}

export interface Props {
  state: DownloadDialogState
  onStateChange: (state: DownloadDialogState) => void
  onDownload: (pdf: Pdf) => Promise<void>
  filename: string
}

export default (props: Props): JSX.Element => {
  const { state, onStateChange, onDownload, filename } = props

  return (
    <Dialog.Root
      open={state.status === 'open'}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          throw new Error('Dialog should never open itself since there is no trigger')
        }
        onStateChange({ status: 'closed' })
      }}
    >
      <Dialog.Portal>
        <Dialog.Content className='fixed inset-0 bg-origami-green-light outline-none'>
          <div className='absolute inset-4 flex flex-col justify-between items-center'>
            <div className='w-full'>
              <OrigamiButton
                label='← Back'
                onClick={() => onStateChange({ status: 'closed' })}
              />
            </div>
            {
              state.status === 'open' && (
                <>
                  <Preview
                    getSignedPdf={state.getSignedPdf}
                    filename={filename}
                    onDownload={async () => await onDownload(await state.getSignedPdf())}
                  />
                  <div className='w-full'>
                    <Actions
                      onDownload={async () => await onDownload(await state.getSignedPdf())}
                    />
                  </div>
                </>
              )
            }
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

function Actions ({ onDownload }: { onDownload: () => Promise<void> }): JSX.Element {
  const [state, setState] = React.useState<'initial' | 'downloading'>('initial')

  return (
    <div>
      <OrigamiButton
        label={state === 'initial' ? '↓ Download' : ' Downloading...'}
        disabled={state === 'downloading'}
        fullWidth
        onClick={() => {
          setState('downloading')
          onDownload()
            .then(() => {
              setState('initial')
            })
            .catch((err) => {
              console.log(err)
              throw err
            })
        }}
      />
    </div>
  )
}

function Preview (
  {
    getSignedPdf,
    filename,
    onDownload
  }: {
    getSignedPdf: Open['getSignedPdf']
    filename: Props['filename']
    onDownload: () => Promise<void>
  }
): JSX.Element {
  const [preview, loadPreview] = useAsync(
    async () => {
      const pdf = await getSignedPdf()
      const page = await pdf.pdfjsDoc.getPage(1)
      const { width, height } = page.getViewport({ scale: 1.0 })
      const desiredSize = { width: 184, height: 237 }
      const scale = Math.min(desiredSize.width / width, desiredSize.height / height)
      return (
        <button
          className='relative outline-none'
          onClick={() => {
            onDownload()
              .catch((err) => {
                console.log(err)
                throw err
              })
          }}
        >
          {
            pdf.pdfjsDoc.numPages > 1 && (
              <div className='absolute rotate-[5deg] translate-x-2.5 shadow-lg'>
                <PdfPagePreview
                  pdf={pdf}
                  pageNumber={2}
                  scale={scale}
                />
              </div>
            )
          }
          <div className='relative shadow-lg'>
            <PdfPagePreview
              pdf={pdf}
              pageNumber={1}
              scale={scale}
            />
          </div>
        </button>
      )
    },
    []
  )
  React.useEffect(loadPreview, [])

  if (preview.status === 'not-executed' || preview.status === 'loading') {
    return <div>Loading...</div>
  }

  if (preview.status === 'error') {
    return <div>Error: {preview.error.message}</div>
  }

  // Show the stack of signed papers.
  return (
    <div className='space-y-8 text-center'>
      <div>{preview.value}</div>
      <div className='font-mono font-[450] text-origami-grass-light-12'>{filename}</div>
    </div>
  )
}
