import React from 'react'
import { Navigate, useNavigate, useRoutes } from 'react-router-dom'

import Heading from 'components/heading/Heading'
import Lede from 'components/lede/Lede'
import Logo from 'components/logo/Logo'
import { recordEvent } from 'features/analytics/google'
import { blankDrawing, isBlank } from 'features/draw/drawing'
import { setSignatures } from 'features/signaturedb'
import { Signature } from 'features/signaturedb/types'

import DrawSignatureRoute from './DrawSignatureRoute'
import ImportRoute from './import/ImportRoute'
import { cropSignature } from 'features/sigcrop'
import SignatureCreatedRoute from './SignatureCreatedRoute'
import { signatureToSvg } from 'features/sigdl'
import DocTypeMenu from 'features/wordimport/DocTypeMenu'

export interface Props {
  onUploadPdfFile: (file: File) => Promise<void>
}

export default (props: Props): JSX.Element => {
  const { onUploadPdfFile } = props
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const navigate = useNavigate()

  function handleDoneWithSignature (signature: Signature): void {
    // Persist the signature so we can use it to sign docs when the use comes back.
    setSignatures(localStorage, [cropSignature(signature, { padding: 4 })])

    // Record the analytics event.
    recordEvent('save_signature')

    // Navigate to the next route.
    navigate('signature-created')
  }

  function handleDownload (): void {
    // Record the analytics event.
    recordEvent('download_signature')

    // Generate an SVG.
    const signature = cropSignature(state.signature)
    const svgFile = signatureToSvg(cropSignature(signature, { padding: 4 }))
    const svgUrl = URL.createObjectURL(svgFile)

    // Draw it onto a canvas.
    const img = new Image()
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas')
      canvas.width = signature.size.width
      canvas.height = signature.size.height
      const context = canvas.getContext('2d')
      context?.drawImage(img, 0, 0, canvas.width, canvas.height)

      // Download the image.
      const a = document.createElement('a')
      a.href = canvas.toDataURL()
      a.download = 'signature.png'
      a.style.opacity = '0'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
    img.setAttribute('src', svgUrl)
  }

  const childRoutes = useRoutes([
    {
      index: true,
      element: (
        <DrawSignatureRoute
          signature={state.signature.drawing}
          onSignatureChange={(drawing, size) => {
            dispatch({
              name: 'update-signature',
              signature: {
                type: 'drawn',
                size,
                drawing
              }
            })
          }}
          onDone={() => handleDoneWithSignature(state.signature)}
        />
      )
    },
    {
      path: 'signature-created',
      element: (
        <>
          {
            isBlank(state.signature.drawing)
              ? (
                <Navigate to='/' replace />
                )
              : (
                <SignatureCreatedRoute
                  signature={state.signature}
                  onBack={() => navigate('')}
                  onDownload={handleDownload}
                  onStickOnDoc={() => navigate('choose-doc-type')}
                />
                )
          }
        </>
      )
    },
    {
      path: 'choose-doc-type/*',
      element: (
        <DocTypeMenu
          onBack={() => navigate('signature-created')}
          onChoose={() => navigate('import')}
        />
      )
    },
    {
      path: 'import/*',
      element: (
        <ImportRoute
          onBack={() => navigate('choose-doc-type')}
          onUpload={onUploadPdfFile}
        />
      )
    }
  ])

  if (childRoutes === null) {
    return (
      <div>Not found.</div>
    )
  }

  return (
    <div className='fixed inset-0 overflow-auto bg-origami-green-light'>
      <div className='p-4'>
        <header>
          <div className='py-2'>
            <Logo />
          </div>
        </header>
        <div className='mx-auto max-w-prose'>
          <main className='mt-6 space-y-2'>
            <div className='space-y-3 h-[222px]'>
              <Heading level={1}>
                Sign a PDF with your finger
              </Heading>
              <Lede>
                If you know how to post Instagram Stories you know how to sign
                a doc with Roger, <b className='font-bold'>for free</b>.
              </Lede>
            </div>
            <div className='text-center'>
              {childRoutes}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

type State = DrawingSignature
interface DrawingSignature {
  status: 'drawing-signature'
  signature: Signature
}

type Action = UpdateSignature
interface UpdateSignature {
  name: 'update-signature'
  signature: Signature
}

const initialState: State = {
  status: 'drawing-signature',
  signature: {
    type: 'drawn',
    size: {
      width: 0,
      height: 0
    },
    drawing: blankDrawing()
  }
}

function reducer (state: State, action: Action): State {
  switch (action.name) {
    case 'update-signature': {
      return {
        status: 'drawing-signature',
        signature: action.signature
      }
    }
  }
}
