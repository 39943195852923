import * as Dialog from '@radix-ui/react-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import SignatureChooser, { Props as SignatureChooserProps }
  from 'components/signature-chooser/SignatureChooser'
import { Signature } from 'features/signaturedb/types'
import React from 'react'

export interface Props {
  signatureLoader: () => Signature[]
  onCreate: () => void
  onChoose: (signature: Signature) => void
  trigger: React.ReactNode
}

export default React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLButtonElement>): JSX.Element => {
    const { signatureLoader, onCreate, onChoose, trigger } = props

    const [isOpen, setOpen] = React.useState(false)

    return (
      <Dialog.Root open={isOpen} onOpenChange={setOpen}>
        <Dialog.Trigger asChild ref={ref}>
          {trigger}
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay
            className='fixed inset-0 backdrop-blur'
          />
          <Dialog.Content
            className='fixed bottom-0 inset-x-0 text-center outline-none'
          >
            <VisuallyHidden.Root>
              <Dialog.Title>Choose a signature</Dialog.Title>
            </VisuallyHidden.Root>
            <NonEmptySignatureChooser
              signatures={signatureLoader()}
              onCreate={() => {
                setOpen(false)
                onCreate()
              }}
              onChoose={(signature) => {
                setOpen(false)
                onChoose(signature)
              }}
            />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    )
  }
)

function NonEmptySignatureChooser (props: SignatureChooserProps): JSX.Element | null {
  const { signatures, onCreate } = props

  React.useEffect(
    () => {
      if (signatures.length === 0) {
        onCreate()
      }
    },
    [signatures.length === 0]
  )

  if (signatures.length === 0) {
    return null
  }

  return (
    <SignatureChooser {...props} />
  )
}
