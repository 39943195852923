import * as ScrollArea from '@radix-ui/react-scroll-area'
import cx from 'classnames'
import { orderBy, toPairs } from 'lodash'
import React from 'react'

import Button from 'components/button/Button'
import IconButton from 'components/icon-button/IconButton'
import DrawingView from 'features/draw/DrawingView'
import { Signature } from 'features/signaturedb/types'

export interface Props {
  // Signatures are expected to be in order of creation (increasing).
  signatures: Signature[]
  onChoose: (signature: Signature) => void
  onCreate: () => void
}

export default (props: Props): JSX.Element => {
  const { signatures, onCreate, onChoose } = props

  return (
    <Container>
      <Signatures
        signatures={signatures}
        onChoose={onChoose}
        onCreate={onCreate}
      />
    </Container>
  )
}

function Container ({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <div
      className={cx(
        'relative'
      )}
    >
      {children}
    </div>
  )
}

function Signatures (
  props: {
    signatures: Signature[]
    onChoose: (signature: Signature) => void
    onCreate: () => void
  }
): JSX.Element {
  const { signatures, onChoose, onCreate } = props

  return (
    <ScrollArea.Root className='relative z-10'>
      <ScrollArea.Viewport>
        <div className='flex justify-center items-stretch p-8 space-x-2'>
          {
            orderBy(toPairs(signatures), [([i]) => i], ['desc'])
              .map(([, signature], index) => (
                <Button
                  key={index}
                  onClick={() => onChoose(signature)}
                  className={cx(
                    'shrink-0 flex outline-none shadow-origami rounded-3xl',
                    'bg-white px-2 py-6 items-center'
                  )}
                >
                  <div
                    className='scale-[70%] origin-top-left'
                    style={{
                      width: Math.round(signature.size.width * 0.70),
                      height: Math.round(signature.size.height * 0.70)
                    }}
                  >
                    <SignatureImage
                      signature={signature}
                    />
                  </div>
                </Button>
              ))
          }
          <Button
            onClick={onCreate}
            className={cx(
              'shrink-0 flex outline-none shadow-origami rounded-3xl bg-white items-center px-8'
            )}
          >
            <IconButton icon='add' style='secondary' />
          </Button>
        </div>
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar orientation='horizontal'>
        <ScrollArea.Thumb />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  )
}

function SignatureImage ({ signature }: { signature: Signature }): JSX.Element {
  return (
    <div
      className='relative'
      style={{
        width: signature.size.width,
        height: signature.size.height
      }}
    >
      <DrawingView
        inkColor='#3451b2'
        penSize={3}
        drawing={signature.drawing}
        pointSamplingRate={3}
        smoothingFactor={0.2}
      />
    </div>
  )
}
