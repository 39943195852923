import cx from 'classnames'
import React from 'react'

import handIconUrl from './pinch.svg'

export default (): JSX.Element => {
  return (
    <div
      className={cx(
        'bg-origami-grass-light-3 px-4 py-2 border border-origami-grass-light-11',
        'rounded shadow-origami-sm max-w-[280px]'
      )}
    >
      <div className='flex flex-col items-center space-y-2'>
        <HandIcon />
        <div
          className={cx(
            'text-origami-grass-light-12 text-sm font-mono font-bold leading-[1.4] text-center'
          )}
        >
          Pinch the doc to zoom to where your signature goes.
        </div>
      </div>
    </div>
  )
}

function HandIcon (): JSX.Element {
  return (
    <img src={handIconUrl} />
  )
}
