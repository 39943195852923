import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { BrowserStorage } from 'features/files/browser'
import { StorageLocation } from 'features/files/storage'
import { loadPdfFromBytes, Pdf } from 'features/pdf'
import { BrowserFirestore } from 'features/persistence/browser'
import {
  generateSessionId, saveSessionDoc, SessionDoc, TransientUploadedSessionDoc
} from 'features/sessions'
import { getSignatures, setSignatures } from 'features/signaturedb'

import HomeRoute from './routes/home/HomeRoute'
import SignRoute, { loader as sessionLoader } from './routes/sign/SignRoute'

export default (): JSX.Element => {
  const db = new BrowserFirestore<SessionDoc>()
  const storage = new BrowserStorage()

  const pdfLoader = async (storageLocation: StorageLocation): Promise<Pdf> => {
    const bytes = await storage.getObjectBytes(storageLocation)
    return await loadPdfFromBytes(bytes)
  }

  const pdfFileUploader = async (file: File): Promise<void> => {
    const sessionId = generateSessionId(db)

    // Upload the file to storage.
    const storageLocation: StorageLocation = {
      bucketName: storage.getDefaultBucketName(),
      objectName: `docs/${sessionId}/${file.name}`
    }
    const buffer = await file.arrayBuffer()
    await storage.setObjectBytes(storageLocation, new Uint8Array(buffer))

    // Create the UploadedSessionDoc in firestore.
    const sessionDoc: TransientUploadedSessionDoc = {
      type: 'uploaded',
      createdTimestamp: Date.now(),
      name: file.name,
      mimeType: 'application/pdf',
      storageLocation
    }
    const savedSessionDoc = await saveSessionDoc(db, sessionId, sessionDoc)

    // Navigate to the session route.
    router.navigate(`/secure/${savedSessionDoc.sessionId}`)
  }

  const router = createBrowserRouter([
    {
      path: 'secure/:sessionId',
      element: (
        <SignRoute
          pdfLoader={pdfLoader}
          signatureLoader={() => getSignatures(localStorage)}
          setSignatures={(signatures) => setSignatures(localStorage, signatures)}
        />
      ),
      loader: sessionLoader(db)
    },
    {
      path: '/*',
      element: (
        <HomeRoute
          onUploadPdfFile={pdfFileUploader}
        />
      )
    }
  ])

  return (
    <RouterProvider router={router} />
  )
}
