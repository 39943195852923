import React from 'react'

import SignaturePad from 'components/signature-pad/SignaturePad'
import { Drawing } from 'features/draw/drawing'
import { Signature } from 'features/signaturedb/types'

export interface Props {
  signature: Drawing
  onSignatureChange: (signature: Drawing, size: Signature['size']) => void
  onDone: () => void
}

export default (props: Props): JSX.Element => {
  const { signature, onSignatureChange, onDone } = props

  return (
    <SignaturePad
      signature={signature}
      onSignatureChange={onSignatureChange}
      getMessageBelowSignatureLine={(state) => (
        state === 'started' ? 'looks good' : 'your signature'
      )}
      instructionCopy={{
        title: 'Draw your signature',
        subtitle: 'You\'ll stick it on your doc or download it, next'
      }}
      doneLabel='Next →'
      onDone={onDone}
    />
  )
}
