import cx from 'classnames'
import React from 'react'

import OrigamiButton from 'components/origami-button/OrigamiButton'
import BackButton from 'components/white-card/BackButton'
import WhiteCard from 'components/white-card/WhiteCard'
import DrawingView from 'features/draw/DrawingView'
import { Signature } from 'features/signaturedb/types'

export interface Props {
  signature: Signature
  onBack: () => void
  onDownload: () => void
  onStickOnDoc: () => void
}

export default (props: Props): JSX.Element => {
  const { signature, onBack, onDownload, onStickOnDoc } = props

  return (
    <WhiteCard>
      <div className='relative h-[316px] flex flex-col justify-between'>
        <div className='absolute inset-0'>
          <DrawingView
            drawing={signature.drawing}
            inkColor='#d7dcda'
            penSize={3}
            pointSamplingRate={3}
            smoothingFactor={0.2}
          />
        </div>
        <div className='absolute top-2 left-2'>
          <BackButton onClick={onBack} />
        </div>
        <div
          className={cx(
            'py-4 px-10 text-center font-bold font-mono text-grass-light-12 leading-[1.5]'
          )}
        >
          What do you want to do with your signature?
        </div>

        <div className='space-y-2 p-2'>
          <OrigamiButton
            fullWidth
            label='↓ Download signature'
            onClick={onDownload}
          />
          <OrigamiButton
            fullWidth
            label='Stick it on a document'
            style='primary'
            chevron
            onClick={onStickOnDoc}
          />
        </div>
      </div>
    </WhiteCard>
  )
}
