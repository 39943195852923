import React from 'react'

/**
 * Uses a function to determine whether the component is mounted.
 *
 * Useful to prevent triggering a state update on a component that no longer
 * exists, which results in a React warning.
 *
 * @returns a function to determine whether the component is mounted.
 */
export default function useIsMounted (): () => boolean {
  const isMounted = React.useRef(false)

  React.useEffect(
    () => {
      isMounted.current = true

      return () => {
        isMounted.current = false
      }
    },
    []
  )

  return React.useCallback(() => isMounted.current, [])
}
