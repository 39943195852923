import React from 'react'
import { Gesture, Point } from 'features/gesture/gesture'
import { SpringRef, SpringValue, useSpring } from '@react-spring/web'

export type StickerId = string

export interface Sticker<N extends string, A> {
  type: N
  position: Point
  scale: number
  attributes: A
}

export interface StickerConfig<A> {
  render: (id: StickerId, isActive: SpringValue<boolean>, attributes: A) => React.ReactNode
}

export interface PaperConfig {
  render: () => React.ReactNode
}

export interface StickerPaperConfig<T> {
  paper: PaperConfig
  stickers: {
    [N in keyof T]: StickerConfig<T[N]>
  }
}

export interface Transform {
  translate: {
    x: number
    y: number
  }
  scale: number
}

export interface StickerState<N, A> {
  id: StickerId
  type: N
  transform: Transform
  attributes: A
}

export class StickerStackController<T> {
  readonly config: StickerPaperConfig<T>
  nextId: number

  constructor (config: StickerPaperConfig<T>) {
    this.config = config
    this.nextId = 1
  }

  generateId (): StickerId {
    const id = this.nextId.toString()
    this.nextId++
    return id
  }

  createSticker <N extends keyof T> (
    typeName: N, transform: Transform, attributes: T[N]
  ): StickerState<N, T[N]> {
    const sticker: StickerState<N, T[N]> = {
      id: this.generateId(),
      type: typeName,
      transform,
      attributes
    }
    return sticker
  }
}

// Returns whether the gesture was handled.
export type GestureListener = (gesture: Gesture) => boolean

export interface StickerPaper<T> {
  stickerStates: Array<{ [N in keyof T]: StickerState<N, T[N]> }[keyof T]>
  addSticker: (type: keyof T, transform: Transform, attributes: T[keyof T]) => StickerId
  updateSticker: (id: StickerId, attributes: T[keyof T]) => void
  removeSticker: (id: StickerId) => void
  getPaperViewportRect: () => DOMRectReadOnly

  // Returns whether the gesture was handled/captured by any stickers.
  onGesture: (gesture: Gesture) => boolean

  getCanvasRef: () => React.RefObject<HTMLDivElement>
  getActiveStickerSpring: () => [
    {
      stickerId: SpringValue<string>
    },
    SpringRef<{
      stickerId: string
    }>
  ]
  getPaperSpring: () => [
    {
      x: SpringValue<number>
      y: SpringValue<number>
      scale: SpringValue<number>
    },
    SpringRef<{
      x: number
      y: number
      scale: number
    }>
  ]
  getStickerConfigForType: (type: keyof T) => StickerConfig<T[keyof T]>
  addGestureListener: (listener: GestureListener) => () => void
  renderPaper: () => React.ReactNode
}

export function useStickerPaper<T> (
  config: StickerPaperConfig<T>
): StickerPaper<T> {
  const [controller] = React.useState(new StickerStackController(config))
  const [stickers, setStickers] = React
    .useState<Array<{ [N in keyof T]: StickerState<N, T[N]> }[keyof T]>>([])
  const [listeners, setListeners] = React.useState<GestureListener[]>([])
  const canvasRef = React.useRef<HTMLDivElement>(null)
  const [paperSpring, paperSpringApi] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1.0
  }))
  const [activeStickerSpring, activeStickerSpringApi] = useSpring(() => ({
    stickerId: ''
  }))

  return {
    stickerStates: stickers,
    addSticker: (type, transform, attributes) => {
      const sticker = controller.createSticker(type, transform, attributes)
      setStickers([
        ...stickers,
        sticker
      ])
      return sticker.id
    },
    updateSticker: (id, attributes) => {
      const stickerIndex = stickers.findIndex((s) => s.id === id)
      if (stickerIndex === -1) {
        return
      }
      setStickers([
        ...stickers.slice(0, stickerIndex),
        {
          ...stickers[stickerIndex],
          attributes
        },
        ...stickers.slice(stickerIndex + 1)
      ])
    },
    removeSticker: (id) => {
      requestAnimationFrame(() => {
        setStickers(stickers.filter((s) => s.id !== id))
      })
    },
    getCanvasRef: () => canvasRef,
    getActiveStickerSpring: () => [activeStickerSpring, activeStickerSpringApi],
    getPaperSpring: () => [paperSpring, paperSpringApi],
    getPaperViewportRect: () => {
      const scale = paperSpring.scale.get()
      return DOMRectReadOnly.fromRect({
        x: -paperSpring.x.get() / scale,
        y: -paperSpring.y.get() / scale,
        width: (canvasRef.current?.clientWidth ?? 0) / scale,
        height: (canvasRef.current?.clientHeight ?? 0) / scale
      })
    },
    onGesture: (gesture) => {
      return listeners.reduce((isHandled, listener) => isHandled || listener(gesture), false)
    },
    getStickerConfigForType: (type) => {
      return config.stickers[type]
    },
    addGestureListener: (listener) => {
      setListeners([
        ...listeners,
        listener
      ])
      return () => {
        setListeners((listeners) => listeners.filter((l) => l !== listener))
      }
    },
    renderPaper: config.paper.render
  }
}
