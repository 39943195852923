import { findLastIndex, range } from 'lodash'
import React from 'react'

import Logo from 'components/logo/Logo'
import DrawingView from 'features/draw/DrawingView'
import { Pdf, PdfPagePreview } from 'features/pdf'
import { AddSignatureChange, AddTextChange, Change } from 'features/pdf/pdflib'
import { Signature } from 'features/signaturedb/types'
import { StickerPaperView, useStickerPaper } from 'features/sticker'
import { StickerId, StickerState } from 'features/sticker/sticker'

import BottomToolbar from './BottomToolbar'
import PinchToZoomTipDialog from './PinchToZoomTipDialog'
import TopToolbar from './TopToolbar'
import { recordEvent } from 'features/analytics/google'

export interface Props {
  pdf: Pdf
  signatureLoader: () => Signature[]
  setSignatures: (signatures: Signature[]) => void
  onNext: (changes: Change[]) => void
}

export default (props: Props): JSX.Element => {
  const { pdf, signatureLoader, setSignatures, onNext } = props
  const outerRef = React.useRef<HTMLDivElement>(null)
  const innerRef = React.useRef<HTMLDivElement>(null)
  const [scale, setScale] = React.useState<null | number>(null)
  const pageCorners = React.useRef<Array<{ x: number, y: number }>>([])
  const [textEditorDialogOpenFor, setTextEditorDialogOpenFor] =
    React.useState<boolean | StickerId>(false)
  const [isPinchToZoomTipOpen, setPinchToZoomTipOpen] = React.useState(true)
  const [isAddSignatureTipOpen, setAddSignatureTipOpen] = React.useState(false)

  React.useEffect(
    () => {
      const remove = stickerPaper.addGestureListener(() => {
        setPinchToZoomTipOpen(false)
        setTimeout(
          () => setAddSignatureTipOpen(true),
          500
        )
        remove()
        return false
      })
    },
    []
  )

  React.useEffect(
    () => {
      // Measure the width of the outerRef, which is the desired width
      // of the rendered PDF page.
      if (outerRef.current === null) {
        return
      }
      if (scale !== null) {
        return
      }
      const desiredWidth = outerRef.current.clientWidth
      pdf.pdfjsDoc.getPage(1)
        .then((page) => {
          const pdfWidth = page.getViewport({ scale: 1 }).width
          setScale(desiredWidth / pdfWidth)
        })
        .catch((err) => {
          console.log(err)
          throw err
        })
    },
    [scale]
  )

  function handleTextEditorDialogDone (
    text: string, size: { width: number, height: number }
  ): void {
    setTextEditorDialogOpenFor(false)
    const viewport = stickerPaper.getPaperViewportRect()
    if (textEditorDialogOpenFor === true) {
      const sScale = 1 / stickerPaper.getPaperSpring()[0].scale.get()
      const sWidth = sScale * size.width
      const sHeight = sScale * size.height
      recordEvent('stick_text')
      stickerPaper.addSticker(
        'text',
        {
          translate: {
            x: viewport.x + (viewport.width - sWidth) / 2,
            y: viewport.y + (viewport.height - sHeight) / 2
          },
          scale: sScale
        },
        { text }
      )
    } else if (typeof textEditorDialogOpenFor === 'string') {
      recordEvent('update_text_sticker')
      stickerPaper.updateSticker(
        textEditorDialogOpenFor,
        { text }
      )
    }
  }

  function handleRender (pageIndex: number): (x?: number, y?: number) => void {
    return (x, y) => {
      // Save the top-left position of each page.
      if (x !== undefined && y !== undefined) {
        pageCorners.current[pageIndex] = { x, y }
      }
    }
  }

  const stickerPaper = useStickerPaper({
    paper: {
      render: () => {
        return (
          <div className='pt-[76px] px-4 pb-4'>
            <div ref={outerRef}>
              <div ref={innerRef} className='inline-block'>
                {
                  innerRef.current !== null && outerRef.current !== null && (
                    <div className='inline-block space-y-2'>
                      {
                        scale !== null && (
                          range(pdf.pdfjsDoc.numPages).map((pageIndex) => {
                            const pageNumber = pageIndex + 1
                            return (
                              <div
                                key={pageNumber}
                                className='shadow-origami inline-block text-[0px]'
                              >
                                <PdfPagePreview
                                  pdf={pdf}
                                  pageNumber={pageNumber}
                                  scale={scale}
                                  resolution={2}
                                  onRender={handleRender(pageIndex)}
                                />
                              </div>
                            )
                          })
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
    },
    stickers: {
      signature: {
        render: (id, isActive, attributes: { signature: Signature }) => {
          const { signature } = attributes
          return (
            <div style={signature.size}>
              <DrawingView
                drawing={signature.drawing}
                inkColor='#3451b2'
                penSize={3}
                pointSamplingRate={3}
                smoothingFactor={0.2}
              />
            </div>
          )
        }
      },
      text: {
        render: (id, isActive, attributes: { text: string }) => {
          const { text } = attributes
          const mouseDownTime = React.useRef(0)
          const tapThresholdMs = 150
          return (
            <div
              className='font-bold text-lg leading-[22px] text-black whitespace-pre text-center'
              onMouseDown={() => {
                mouseDownTime.current = Date.now()
              }}
              onMouseUp={() => {
                if (Date.now() - mouseDownTime.current < tapThresholdMs) {
                  requestAnimationFrame(
                    () => setTextEditorDialogOpenFor(id)
                  )
                }
              }}
              onTouchStart={() => {
                mouseDownTime.current = Date.now()
              }}
              onTouchEnd={() => {
                if (Date.now() - mouseDownTime.current < tapThresholdMs) {
                  requestAnimationFrame(
                    () => setTextEditorDialogOpenFor(id)
                  )
                }
              }}
            >
              {text}
            </div>
          )
        }
      }
    }
  })

  function handleNextClick (): void {
    if (scale === null) {
      return
    }
    const changes: Change[] = stickerPaper.stickerStates.map((stickerState): Change => {
      switch (stickerState.type) {
        case 'signature': {
          return {
            type: 'add-signature',
            location: getChangeLocation(scale, {
              x: stickerState.transform.translate.x,
              y: stickerState.transform.translate.y,
              scale: stickerState.transform.scale
            }),
            signature: stickerState.attributes.signature
          }
        }
        case 'text': {
          return {
            type: 'add-text',
            location: getChangeLocation(scale, {
              x: stickerState.transform.translate.x,
              y: stickerState.transform.translate.y,
              scale: stickerState.transform.scale
            }),
            text: stickerState.attributes.text
          }
        }
        default: {
          const exhaustiveCheck: never = stickerState
          throw new Error(`Sticker type not handled: ${JSON.stringify(exhaustiveCheck)}`)
        }
      }
    })
    onNext(changes)
  }

  // Given a point in the coordinate space of the sticker paper, find out its
  // page of the PDF and where the offset on the page is.
  function getChangeLocation (
    pdfScale: number,
    transform: { x: number, y: number, scale: number }
  ): (AddSignatureChange | AddTextChange)['location'] {
    const pageIndex = findLastIndex(pageCorners.current, ({ y: pageY }) => {
      return transform.y >= pageY
    })
    const pageNumber = pageIndex === -1 ? 1 : pageIndex + 1
    const { x: pageX, y: pageY } = pageCorners.current[pageNumber - 1]
    return {
      pageNumber,
      x: (transform.x - pageX) / pdfScale,
      y: (transform.y - pageY) / pdfScale,
      scale: transform.scale / pdfScale
    }
  }

  return (
    <div
      className='fixed inset-0 touch-none bg-[#EEF5F1] cursor-grab overflow-hidden'
    >
      <div className='pt-8 pl-4'>
        <Logo />
      </div>
      <StickerPaperView
        className='absolute inset-0 select-none'
        state={stickerPaper}
      >
        <TopToolbar
          onNext={handleNextClick}
          isHiddenFrameValue={
            // Hide if there is a sticker being moved.
            stickerPaper.getActiveStickerSpring()[0].stickerId.to((id) => id !== '')
          }
        />
        <BottomToolbar
          activeStickerIdFrameValue={
            stickerPaper.getActiveStickerSpring()[0].stickerId.to((id) => id !== '' ? id : null)
          }
          isAddSignatureTipOpen={isAddSignatureTipOpen}
          onAddSignatureTipOpenChange={setAddSignatureTipOpen}
          signatureLoader={signatureLoader}
          onChooseSignature={(signature) => {
            const viewport = stickerPaper.getPaperViewportRect()
            const sScale = 0.7 / stickerPaper.getPaperSpring()[0].scale.get()
            const sWidth = signature.size.width * sScale
            const sHeight = signature.size.height * sScale
            recordEvent('stick_signature')
            stickerPaper.addSticker(
              'signature',
              {
                translate: {
                  x: viewport.x + (viewport.width - sWidth) / 2.0,
                  y: viewport.y + (viewport.height - sHeight) / 2.0
                },
                scale: sScale
              },
              { signature }
            )
          }}
          onCreateSignature={(signature) => {
            recordEvent('create_new_signature')
            setSignatures([...signatureLoader(), signature])
            const viewport = stickerPaper.getPaperViewportRect()
            const sScale = 0.7 / stickerPaper.getPaperSpring()[0].scale.get()
            const sWidth = signature.size.width * sScale
            const sHeight = signature.size.height * sScale
            recordEvent('stick_signature')
            stickerPaper.addSticker(
              'signature',
              {
                translate: {
                  x: viewport.x + (viewport.width - sWidth) / 2.0,
                  y: viewport.y + (viewport.height - sHeight) / 2.0
                },
                scale: sScale
              },
              { signature }
            )
          }}
          onRemoveSticker={(stickerId) => {
            recordEvent('remove_sticker')
            stickerPaper.removeSticker(stickerId)
          }}
          textEditorDialogState={textEditorDialogOpenFor}
          onTextEditorDialogStateChange={setTextEditorDialogOpenFor}
          onTextEditorDialogDone={handleTextEditorDialogDone}
          getStickerText={(stickerId) => {
            return stickerPaper
              .stickerStates
              .find((s): s is StickerState<'text', { text: string }> => (
                s.id === stickerId
              ))
              ?.attributes
              .text ?? null
          }}
        />
      </StickerPaperView>
      <PinchToZoomTipDialog
        open={isPinchToZoomTipOpen}
        onOpenChange={setPinchToZoomTipOpen}
      />
    </div>
  )
}
