import { Point } from 'features/draw/drawing'

export interface Vector {
  scalar: number
  radians: number
}

export function vectorDifference (from: Point, to: Point): Vector {
  const dx = to.x - from.x
  const dy = -(to.y - from.y) // flip the y axis.
  const scalar = Math.sqrt(dx * dx + dy * dy)
  const radians = Math.atan2(dy, dx)
  return {
    scalar,
    radians
  }
}

export function scaleVector (factor: number, vector: Vector): Vector {
  return {
    scalar: vector.scalar * factor,
    radians: vector.radians
  }
}

export function addVectorToPoint (point: Point, vector: Vector): Point {
  return {
    x: point.x + vector.scalar * Math.cos(vector.radians),

    // Note the subtraction because the y-axis is flipped.
    y: point.y - vector.scalar * Math.sin(vector.radians)
  }
}
