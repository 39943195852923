import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import TextEditor from 'components/text-editor/TextEditor'

export interface Props {
  open: boolean | string
  onOpenChange: (open: boolean) => void
  trigger: React.ReactNode
  onDone: (value: string, size: { width: number, height: number }) => void
}

export default (props: Props): JSX.Element => {
  const { open, onOpenChange, trigger, onDone } = props

  const ref = React.useRef<HTMLTextAreaElement>(null)

  React.useEffect(
    () => {
      requestAnimationFrame(
        () => ref.current?.select()
      )
    },
    [typeof open === 'string']
  )

  return (
    <Dialog.Root open={open !== false} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        {trigger}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Content>
          <TextEditor
            ref={ref}
            initialText={typeof open === 'string' ? open : undefined}
            onDone={onDone}
            className='font-bold text-lg leading-[22px] text-black'
            autoFocus
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
