import emailAddresses from 'email-addresses'

export interface EmailContact {
  name?: string
  email: string
}

/**
 * Extracts name/email from an email address (e.g. "First Last <user@domain.com>")
 */
export function contactFromRfc5322 (line: string): EmailContact {
  const parsed = emailAddresses.parseOneAddress(line)
  if (parsed === null) {
    throw new Error('Unable to parse email address: ' + line)
  }
  switch (parsed.type) {
    case 'group': {
      throw new Error('Unable to handle group emails: ' + line)
    }
    case 'mailbox': {
      return {
        ...(parsed.name === null ? {} : { name: parsed.name }),
        email: parsed.address
      }
    }
  }
}
