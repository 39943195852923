export const IMPORT_OPTIONS = {
  GDRIVE: {
    id: 'gdrive',
    label: 'Choose from Google Drive'
  },
  PICTURE: {
    id: 'picture',
    label: 'Take a picture'
  },
  UPLOAD: {
    id: 'upload',
    label: 'Upload a PDF file'
  },
  OTHER: {
    id: 'other',
    label: 'Other'
  },
  EMAIL: {
    id: 'email',
    label: 'Import via email',
    primary: true
  }
} as const

export type ImportOptions = typeof IMPORT_OPTIONS
export type ImportOptionId = ImportOptions[keyof ImportOptions]['id']
export type ImportOptionLabel = ImportOptions[keyof ImportOptions]['label']
export type ImportOption<ID extends keyof ImportOptions = keyof ImportOptions> = ImportOptions[ID]
