import { every, isArray } from 'lodash'
import { isSignature, Signature } from './types'

const KEY = 'signatures'

export function getSignatures (storage: Storage): Signature[] {
  const value = storage.getItem(KEY)
  if (value === null) {
    return []
  }
  return decodeFromJson(value)
}

function decodeFromJson (value: string): Signature[] {
  const signatures = JSON.parse(value)
  if (!isArray(signatures) || !every(signatures, isSignature)) {
    throw new Error(`Expected Signature[] but found ${value}`)
  }
  return signatures
}

export function setSignatures (storage: Storage, signatures: Signature[]): void {
  storage.setItem(KEY, encodeToJson(signatures))
}

function encodeToJson (signatures: Signature[]): string {
  return JSON.stringify(signatures)
}
