import cx from 'classnames'
import React from 'react'

export default React.forwardRef((
  props: React.HTMLAttributes<HTMLDivElement>,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const { className, ...rest } = props
  return (
    <div
      ref={ref}
      className={cx(
        'shadow-origami inline-block w-full max-w-[480px] bg-white rounded-3xl',
        className
      )}
      {...rest}
    />
  )
})
