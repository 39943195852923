import cx from 'classnames'
import OrigamiButton from 'components/origami-button/OrigamiButton'
import BackButton from 'components/white-card/BackButton'
import React from 'react'
import AnswerInput from './AnswerInput'

export interface Props {
  onBack: () => void
}

export default (props: Props): JSX.Element => {
  const { onBack } = props
  const [state, dispatch] = React.useReducer(reducer, initialState)

  function getBody (): JSX.Element {
    switch (state.status) {
      case 'collecting-input': {
        return (
          <>
            <div className='px-4 space-y-3'>
              <div
                className='text-origami-sage-light-12 font-mono italic leading-[1.3] font-[450]'
              >
                How would you like to import your PDF document?
              </div>
              <AnswerInput
                value={state.answer}
                onChange={(answer) => dispatch({ name: 'update-answer', answer })}
              />
            </div>
            <div className='absolute top-0 left-2'>
              <BackButton
                onClick={onBack}
              />
            </div>
          </>
        )
      }
      case 'accepted-input': {
        return (
          <div
            className={cx(
              'italic font-mono font-[450] leading-[1.3] text-origami-sage-light-11 px-4',
              'space-y-6'
            )}
          >
            <p>
              Thanks! We’ll try to make that option available to you soon.
            </p>
            <p>
              In the mean time, try another way to import.
            </p>
          </div>
        )
      }
    }
  }

  function getCtaButton (): JSX.Element {
    switch (state.status) {
      case 'collecting-input': {
        return (
          <OrigamiButton
            label='Submit →'
            style='primary'
            fullWidth
            onClick={() => dispatch({ name: 'submit' })}
          />
        )
      }
      case 'accepted-input': {
        return (
          <OrigamiButton
            label='← Back to Import options'
            fullWidth
            onClick={onBack}
          />
        )
      }
    }
  }

  return (
    <div className='relative flex flex-col h-full justify-between'>
      <div className='space-y-2'>
        <div
          className={cx(
            'py-4 px-10 text-center font-bold font-mono text-grass-light-12 leading-[1.5]',
            'whitespace-nowrap'
          )}
        >
          Other
        </div>
        {getBody()}
      </div>
      <div className='p-2'>
        {getCtaButton()}
      </div>
    </div>
  )
}

type State = CollectingInput | AcceptedInput
interface CollectingInput {
  status: 'collecting-input'
  answer: string
}
interface AcceptedInput {
  status: 'accepted-input'
}

const initialState: State = {
  status: 'collecting-input',
  answer: ''
}

type Action = Submit | UpdateAnswer
interface Submit {
  name: 'submit'
}
interface UpdateAnswer {
  name: 'update-answer'
  answer: string
}

function reducer (state: State, action: Action): State {
  switch (action.name) {
    case 'submit': {
      return {
        status: 'accepted-input'
      }
    }
    case 'update-answer': {
      return {
        status: 'collecting-input',
        answer: action.answer
      }
    }
  }
}
