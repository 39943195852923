import cx from 'classnames'
import OrigamiButton from 'components/origami-button/OrigamiButton'
import React from 'react'

export interface Props {
  label: string
  onBack: () => void
}

export default (props: Props): JSX.Element => {
  const { label, onBack } = props

  return (
    <div className='relative flex flex-col h-full justify-between'>
      <div className='space-y-2'>
        <div
          className={cx(
            'py-4 px-10 text-center font-bold font-mono text-grass-light-12 leading-[1.5]',
            'whitespace-nowrap'
          )}
        >
          {label}
        </div>
        <div
          className={cx(
            'italic font-mono font-[450] tracking-[1.3] text-origami-sage-light-11 px-4'
          )}
        >
          This feature is coming soon. We know it’s annoying, sorry.
        </div>
      </div>
      <div className='p-2'>
        <OrigamiButton
          label='← Back'
          fullWidth
          onClick={onBack}
        />
      </div>
    </div>
  )
}
