import { collection, doc, DocumentData, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import { IsomorphicFirestore, TypePredicate } from './firestore'

export class BrowserFirestore<T extends DocumentData> implements IsomorphicFirestore<T> {
  generateId (collectionPath: string): string {
    const db = getFirestore()
    return doc(collection(db, collectionPath)).id
  }

  async get (docPath: string, typePredicate: TypePredicate<T>): Promise<T | null> {
    const db = getFirestore()
    const ref = doc(db, docPath)
    const snapshot = await getDoc(ref)
    if (!snapshot.exists()) {
      return null
    }
    const docData = snapshot.data()
    if (docData === undefined || !typePredicate(docData)) {
      throw new Error(`Type of firestore document was not as expected: ${JSON.stringify(docData)}`)
    }
    return docData
  }

  async set (docPath: string, docData: T): Promise<void> {
    const db = getFirestore()
    const ref = doc(db, docPath)
    await setDoc(ref, docData)
  }
}
