import React from 'react'

import useIsMounted from './useIsMounted'

/**
 * Resolves an async request.
 *
 * @returns an async result that can be used to determine
 * the status of the request, and depending on whether it
 * succeeded or failed, its resulting value or error details.
 */
export function useAsync<F extends () => Promise<T>, T> (
  fn: (...args: Parameters<F>) => Promise<T>, deps: React.DependencyList
): [
    AsyncResult<T>,
    ExecuteFn<F>
  ] {
  const isMounted = useIsMounted()

  const [state, dispatch] = React.useReducer<Reducer<T>>(reducer, initialState)

  // TODO: Deal with the situation where a client calls execute more than once.
  // TODO: Allow a client to cancel a request.
  const execute = React.useCallback(
    (...args: Parameters<F>) => {
      dispatch({ name: 'executed' })
      fn(...args)
        .then((value) => {
          if (isMounted()) {
            dispatch({ name: 'succeeded', value })
          }
        })
        .catch((error) => {
          if (isMounted()) {
            dispatch({ name: 'failed', error })
          }
        })
    },
    deps
  )

  return [state, execute]
}

/**
 * The result of the async function call.
 *
 * It starts out in 'not-executed' state and moves to 'loading' after
 * the ExecuteFn is called. When it completes, it moves to either
 * 'success' or 'error' state depending on whether the promise returned
 * from the async function resolved or rejected.
 */
export type AsyncResult<T> = (
  AsyncResult$NotExecuted | AsyncResult$Loading | AsyncResult$Success<T> | AsyncResult$Error
)
export interface AsyncResult$NotExecuted {
  status: 'not-executed'
}
export interface AsyncResult$Loading {
  status: 'loading'
}
export interface AsyncResult$Success<T> {
  status: 'success'
  value: T
}
export interface AsyncResult$Error {
  status: 'error'
  error: Error
}

/**
 * Triggers execution of the async function when called.
 *
 * Until a client calls this function, the AsyncResult returned from
 * the useAsync hook will remain in 'not-executed' state.
 */
export type ExecuteFn<F extends () => any> = (...args: Parameters<F>) => void

/// /////////////////////////////////////////////////////////////////////////////
// Internal state management for the hook.
/// /////////////////////////////////////////////////////////////////////////////

/**
 * Initial state.
 */
const initialState: AsyncResult<any> = {
  status: 'not-executed'
}

/**
 * Actions.
 */
type AsyncAction<T> = (
  AsyncAction$Executed | AsyncAction$Succeeded<T> | AsyncAction$Failed
)
interface AsyncAction$Executed {
  name: 'executed'
}
interface AsyncAction$Succeeded<T> {
  name: 'succeeded'
  value: T
}
interface AsyncAction$Failed {
  name: 'failed'
  error: Error
}

/**
 * Reducer.
 */
type Reducer<T> = React.Reducer<AsyncResult<T>, AsyncAction<T>>
function reducer<T> (_: AsyncResult<T>, action: AsyncAction<T>): AsyncResult<T> {
  switch (action.name) {
    case 'executed': {
      return {
        status: 'loading'
      }
    }
    case 'succeeded': {
      return {
        status: 'success',
        value: action.value
      }
    }
    case 'failed': {
      return {
        status: 'error',
        error: action.error
      }
    }
  }
}
