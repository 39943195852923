import { every, isArray, isNumber, last, tail } from 'lodash'

export interface Point {
  x: number
  y: number
}

export function isPoint (obj: any): obj is Point {
  return isNumber(obj?.x) && isNumber(obj?.y)
}

export interface Path {
  points: Point[]
}

export function isPath (obj: any): obj is Path {
  return isArray(obj?.points) && every(obj.points, isPoint)
}

export interface Drawing {
  paths: Path[]
}

export function isDrawing (obj: any): obj is Drawing {
  return isArray(obj?.paths) && every(obj.paths, isPath)
}

export function blankDrawing (): Drawing {
  return {
    paths: []
  }
}

export function isBlank (drawing: Drawing): boolean {
  return drawing.paths.length === 0
}

export function extendPath (path: Path, ...points: Point[]): Path {
  return {
    points: [
      ...path.points,
      ...points
    ]
  }
}

export function addPath (drawing: Drawing, path: Path): Drawing {
  return {
    paths: [
      ...drawing.paths,
      path
    ]
  }
}

export function getLastPath (drawing: Drawing): Path {
  return last(drawing.paths) ?? { points: [] }
}

export function replaceLastPath (drawing: Drawing, path: Path): Drawing {
  return {
    paths: [
      ...(
        drawing.paths.length > 0
          ? drawing.paths.slice(0, drawing.paths.length - 1)
          : []
      ),
      path
    ]
  }
}

export function toSvgPathDefinition (drawing: Drawing): string {
  return drawing.paths
    .map(toSvgPath)
    .filter((def) => def !== null)
    .join(' ')

  function toSvgPath (path: Path): string | null {
    const firstPoint = path.points[0]
    if (firstPoint === undefined) {
      return null
    }
    const header = `M ${firstPoint.x} ${firstPoint.y}`
    const tailer = tail(path.points)
      .map((point) => {
        return `L ${point.x} ${point.y}`
      })
      .join(' ')
    return `${header} ${tailer}`
  }
}
