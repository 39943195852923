import load from 'load-script'
import { isProduction, isStorybook, isLiveChannel } from 'util/env'

declare global {
  interface Window {
    dataLayer?: Array<Record<string | number, any>>
    gtag?: (...args: any[]) => void
  }
}

export function initializeGoogleAnalytics (): void {
  if (isProduction() && !isStorybook() && isLiveChannel()) {
    window.dataLayer = window.dataLayer ?? []
    window.gtag = function () {
      window.dataLayer?.push(arguments)
    }
    window.gtag?.('js', new Date())
    window.gtag?.('config', 'G-2YGPX1PMTS')
    load('https://www.googletagmanager.com/gtag/js?id=G-2YGPX1PMTS', { async: true }, (err) => {
      if (err !== null) {
        console.log(err)
        throw err
      }
    })
  }
}

export type GoogleAnalyticsEventName =
  'open_imported_doc' |
  'clear_signature' |
  'save_signature' |
  'download_signature' |
  'finish_editing_doc' |
  'create_new_signature' |
  'stick_signature' |
  'stick_text' |
  'update_text_sticker' |
  'remove_sticker' |
  'download_signed_doc' |
  'attempt_word_doc_import'

export function recordEvent (eventName: GoogleAnalyticsEventName): void {
  if (isProduction() && !isStorybook() && isLiveChannel()) {
    window.gtag?.('event', eventName)
  }
}

//
// Experiments via Google Optimize.
//

export const EXPERIMENTS = {
  'Import Options': {
    id: 'V1gsvd8BRT-njtc_7jk93g',
    variants: [
      'choose_from_google_drive_first',
      'take_a_picture_first',
      'upload_a_pdf_file_first'
    ] as const
  }
} as const

export type ExperimentName = keyof(typeof EXPERIMENTS)
export type ExperimentDef<ID extends ExperimentName> = typeof EXPERIMENTS[ID]
export type ExperimentId = ExperimentDef<ExperimentName>['id']
export type ExperimentVariants<ID extends ExperimentName> = ExperimentDef<ID>['variants']
export type ExperimentVariant<ID extends ExperimentName> = ExperimentVariants<ID>[number]

export async function getExperimentVariant<N extends ExperimentName> (
  name: N
): Promise<ExperimentVariant<N>> {
  if (!(isProduction() && !isStorybook() && isLiveChannel())) {
    // Always return original variant if not production.
    return EXPERIMENTS[name].variants[0]
  }

  return await new Promise((resolve, reject) => {
    window.gtag?.('event', 'optimize.callback', {
      name: EXPERIMENTS[name].id,
      callback: (value: any) => {
        const index = parseInt(value, 10)
        const variantName = EXPERIMENTS[name].variants[index]
        if (variantName === undefined) {
          reject(new Error('Unrecognized variant index: ' + JSON.stringify(value)))
          return
        }
        resolve(variantName)
      }
    })
  })
}
