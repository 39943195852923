import { map, max, min } from 'lodash'

import { Signature } from 'features/signaturedb/types'

export interface CropOptions {
  padding: number
}

export function cropSignature (signature: Signature, opts?: CropOptions): Signature {
  const padding = opts?.padding ?? 0
  const allPoints = signature.drawing.paths.flatMap((path) => path.points)
  const topLeft = {
    x: min(map(allPoints, 'x')) ?? 0,
    y: min(map(allPoints, 'y')) ?? 0
  }
  const bottomRight = {
    x: max(map(allPoints, 'x')) ?? 0,
    y: max(map(allPoints, 'y')) ?? 0
  }
  const paddedTopLeft = {
    x: topLeft.x - padding,
    y: topLeft.y - padding
  }
  const paddedBottomRight = {
    x: bottomRight.x + padding,
    y: bottomRight.y + padding
  }
  const size = {
    width: paddedBottomRight.x - paddedTopLeft.x,
    height: paddedBottomRight.y - paddedTopLeft.y
  }
  return {
    type: 'drawn',
    size,
    drawing: {
      paths: signature.drawing.paths.map(({ points }) => ({
        points: points.map(({ x, y }) => ({
          x: x - paddedTopLeft.x,
          y: y - paddedTopLeft.y
        }))
      }))
    }
  }
}
