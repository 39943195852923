import * as Dialog from '@radix-ui/react-dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import SignaturePad from 'components/signature-pad/SignaturePad'
import { blankDrawing, Drawing } from 'features/draw/drawing'
import { cropSignature } from 'features/sigcrop'
import { Signature } from 'features/signaturedb/types'
import React from 'react'

export interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onCreate: (signature: Signature) => void
}

export default (props: Props): JSX.Element => {
  const { open, onOpenChange, onCreate } = props

  const [drawing, setDrawing] = React.useState(blankDrawing())
  const [size, setSize] = React.useState({ width: 0, height: 0 })

  React.useEffect(
    () => {
      if (open) {
        // When the dialog first opens, clear the drawing.
        setDrawing(blankDrawing())
      }
    },
    [open]
  )

  function handleSignatureChange (drawing: Drawing, size: Signature['size']): void {
    setDrawing(drawing)
    setSize(size)
  }

  function handleDone (): void {
    onCreate(
      cropSignature(
        { type: 'drawn', size, drawing },
        { padding: 4 }
      )
    )
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className='fixed inset-0 backdrop-blur' />
        <Dialog.Content className='h-0 fixed bottom-[332px] inset-x-4 text-center outline-none'>
          <VisuallyHidden.Root>
            <Dialog.Title>Draw your signature</Dialog.Title>
          </VisuallyHidden.Root>
          <SignaturePad
            getMessageBelowSignatureLine={() => 'draw your signature'}
            signature={drawing}
            onSignatureChange={handleSignatureChange}
            doneLabel='Done'
            onDone={handleDone}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
