import { animated } from '@react-spring/web'
import { Gesture } from 'features/gesture/gesture'
import GestureCanvas from 'features/gesture/GestureCanvas'
import React from 'react'
import { StickerPaper } from './sticker'
import StickerView from './StickerView'

export interface Props<T>
  extends React.HTMLAttributes<HTMLDivElement> {
  state: StickerPaper<T>
  children?: React.ReactNode
}

function StickerPaperView<T> (props: Props<T>): JSX.Element {
  const { state, children, ...rest } = props
  const [offset, setOffset] = React.useState<null | { x: number, y: number }>({ x: 0, y: 0 })
  const canvasRef = state.getCanvasRef()
  const paperRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(
    () => {
      if (canvasRef.current === null) {
        return
      }
      const rect = canvasRef.current.getBoundingClientRect()
      setOffset({ x: rect.left, y: rect.top })
    },
    []
  )

  const [paperSpring, paperSpringApi] = state.getPaperSpring()
  const start = React.useRef({ x: 0, y: 0, scale: 1.0, origin: { x: 0, y: 0 } })

  const handlePaperGesture = (gesture: Gesture): void => {
    if (offset === null) {
      return
    }
    switch (gesture.type) {
      case 'start': {
        if (paperRef.current === null) {
          return
        }
        const rect = paperRef.current.getBoundingClientRect()
        start.current.x = paperSpring.x.get()
        start.current.y = paperSpring.y.get()
        start.current.scale = paperSpring.scale.get()
        start.current.origin.x = offset.x + gesture.x - rect.left
        start.current.origin.y = offset.y + gesture.y - rect.top
        break
      }
      case 'translate': {
        paperSpringApi.start({
          x: start.current.x + gesture.x,
          y: start.current.y + gesture.y,
          immediate: true
        })
        break
      }
      case 'scale': {
        paperSpringApi.start({
          x: start.current.x + gesture.x - start.current.origin.x * (gesture.factor - 1),
          y: start.current.y + gesture.y - start.current.origin.y * (gesture.factor - 1),
          scale: start.current.scale * gesture.factor,
          immediate: true
        })
        break
      }
    }
  }

  return (
    <div
      {...rest}
      ref={canvasRef}
    >
      {
        offset !== null && (
          <GestureCanvas
            onGesture={(gesture) => {
              if (state.onGesture(gesture)) {
                return
              }
              handlePaperGesture(gesture)
            }}
            className='absolute inset-0'
          >
            <animated.div
              ref={paperRef}
              className='absolute inset-0 origin-top-left'
              style={paperSpring}
            >
              <div className='absolute inset-0'>
                {state.renderPaper()}
              </div>
              {
                state.stickerStates.map((sticker) => {
                  return (
                    <StickerView
                      key={sticker.id}
                      clientOffset={offset}
                      paperOffset={sticker.transform.translate}
                      paperScale={paperSpring.scale}
                      config={state.getStickerConfigForType(sticker.type)}
                      sticker={sticker}
                      addGestureListener={state.addGestureListener}
                      onTransformChange={(transform) => {
                        sticker.transform = transform
                      }}
                      onActiveChange={(isActive) => {
                        state.getActiveStickerSpring()[1].start({
                          stickerId: isActive ? sticker.id : '',
                          immediate: true
                        })
                      }}
                    />
                  )
                })
              }
            </animated.div>
          </GestureCanvas>
        )
      }
      {children}
    </div>
  )
}

export default StickerPaperView
