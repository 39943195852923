import { H } from 'highlight.run'
import { isString } from 'lodash'
import React from 'react'
import { LoaderFunction } from 'react-router-dom'

import { StorageLocation } from 'features/files/storage'
import { applyChangesToPdf, Pdf } from 'features/pdf'
import { IsomorphicFirestore } from 'features/persistence/firestore'
import { getSessionDoc, isEmailedSessionDoc, isSessionDoc, SessionDoc } from 'features/sessions'
import Signer from 'features/sign/Signer'
import { Signature } from 'features/signaturedb/types'
import { useTypedLoaderData } from 'hosting/hooks'
import { useAsync } from 'util/hooks'

import DownloadDialog, { DownloadDialogState } from './DownloadDialog'
import { contactFromRfc5322 } from 'features/email'
import { recordEvent } from 'features/analytics/google'

export function loader (db: IsomorphicFirestore<SessionDoc>): LoaderFunction {
  return async ({ params: { sessionId } }): Promise<SessionDoc | null> => {
    if (!isString(sessionId)) {
      throw new Error('Expected sessionId param for this route.')
    }
    const sessionDoc = await getSessionDoc(db, sessionId)

    // Identify with highlight if in production.
    if (sessionDoc !== null && process.env.NODE_ENV === 'production') {
      if (isEmailedSessionDoc(sessionDoc)) {
        const { email, name: displayName } = contactFromRfc5322(sessionDoc.from)
        H.identify(email, {
          ...(displayName === undefined ? {} : { highlightDisplayName: displayName })
        })
      }
    }

    return sessionDoc
  }
}

export interface Props {
  pdfLoader: (storageLocation: StorageLocation) => Promise<Pdf>
  signatureLoader: () => Signature[]
  setSignatures: (signatures: Signature[]) => void
}

export default (props: Props): JSX.Element => {
  const { pdfLoader, signatureLoader, setSignatures } = props
  const sessionDoc = useTypedLoaderData(isSessionDoc)

  if (sessionDoc === null) {
    return (
      <div>Not found.</div>
    )
  }

  return (
    <SignRouteFound
      pdfLoader={pdfLoader}
      signatureLoader={signatureLoader}
      setSignatures={setSignatures}
      sessionDoc={sessionDoc}
    />
  )
}

export interface FoundProps {
  sessionDoc: SessionDoc
  pdfLoader: Props['pdfLoader']
  signatureLoader: Props['signatureLoader']
  setSignatures: Props['setSignatures']
}

export const SignRouteFound = (props: FoundProps): JSX.Element | null => {
  const { sessionDoc, pdfLoader, signatureLoader, setSignatures } = props

  const [downloadDialogState, setDownloadDialogState] = React.useState<DownloadDialogState>({
    status: 'closed'
  })

  React.useEffect(
    () => {
      recordEvent('open_imported_doc')
    },
    [sessionDoc]
  )

  const [pdf, loadPdf] = useAsync(
    async () => await pdfLoader(sessionDoc.storageLocation),
    [sessionDoc]
  )
  React.useEffect(loadPdf, [sessionDoc])

  switch (pdf.status) {
    case 'not-executed': {
      return null
    }
    case 'loading': {
      return null
    }
    case 'error': {
      return (
        <div>
          {pdf.error.message}
        </div>
      )
    }
    case 'success': {
      return (
        <>
          <Signer
            pdf={pdf.value}
            signatureLoader={signatureLoader}
            setSignatures={setSignatures}
            onNext={(changes) => {
              recordEvent('finish_editing_doc')
              setDownloadDialogState({
                status: 'open',
                getSignedPdf: async () => await applyChangesToPdf(pdf.value, changes)
              })
            }}
          />
          <DownloadDialog
            state={downloadDialogState}
            onStateChange={setDownloadDialogState}
            onDownload={async (pdf) => {
              recordEvent('download_signed_doc')
              // Surprise! Add a watermark to the first page.
              const watermarkedPdf = await applyChangesToPdf(pdf, [{
                type: 'add-watermark'
              }])
              const file = new File([await watermarkedPdf.pdfjsDoc.getData()], sessionDoc.name, {
                type: 'application/pdf'
              })
              const url = URL.createObjectURL(file)
              window.location.href = url
            }}
            filename={sessionDoc.name}
          />
        </>
      )
    }
  }
}
