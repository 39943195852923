import { useGesture } from '@use-gesture/react'
import cx from 'classnames'
import React from 'react'
import { Gesture } from './gesture'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onGesture: (gesture: Gesture) => void
}

/**
 * This canvas translates touch and mouse events into a stream of commands
 * that make it easier to reason about manipulations using the analogy of
 * dragging, resizing, and rotating pieces of scap paper on a canvas.
 */
export default (props: Props): JSX.Element | null => {
  const { onGesture, className, ...rest } = props

  const ref = React.useRef<HTMLDivElement>(null)

  useGesture(
    {
      onDrag: ({ first, last, movement: [dx, dy], xy: [x, y], pinching, cancel }) => {
        if (ref.current === null) {
          return
        }
        if (pinching === true) {
          cancel()
          return
        }
        const rect = ref.current.getBoundingClientRect()
        if (first) {
          onGesture({
            type: 'start',
            x: x - rect.left,
            y: y - rect.top
          })
        }
        onGesture({
          type: 'translate',
          x: dx,
          y: dy
        })
        if (last) {
          onGesture({
            type: 'stop'
          })
        }
      },
      onPinch: ({ first, last, movement: [ds], origin: [x, y], memo }) => {
        if (ref.current === null) {
          return
        }
        const rect = ref.current.getBoundingClientRect()
        if (first) {
          onGesture({
            type: 'start',
            x: x - rect.left,
            y: y - rect.top
          })
          return { x, y }
        }
        onGesture({
          type: 'scale',
          factor: ds,
          x: x - memo.x,
          y: y - memo.y
        })
        if (last) {
          onGesture({
            type: 'stop'
          })
        }
        return memo
      }
    },
    {
      target: ref
    }
  )
  React.useEffect(
    () => {
      const preventDefault = (e: Event): void => {
        e.preventDefault()
      }
      document.addEventListener('gesturestart', preventDefault)
      document.addEventListener('gesturechange', preventDefault)
      return () => {
        document.removeEventListener('gesturestart', preventDefault)
        document.removeEventListener('gesturechange', preventDefault)
      }
    },
    []
  )

  return (
    <div
      ref={ref}
      className={cx('touch-none', className)}
      {...rest}
    />
  )
}
