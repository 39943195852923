import cx from 'classnames'
import React from 'react'

import Button from 'components/button/Button'

import rightChevronImageUrl from './right-chevron.svg'

export type Style = 'default' | 'primary' | 'discouraged'

export interface Props {
  label: string
  onClick?: () => void
  disabled?: boolean
  style?: Style
  fullWidth?: boolean
  chevron?: boolean
}

export default (props: Props): JSX.Element => {
  const { label, onClick, disabled, style, fullWidth, chevron } = props

  const bgClass = disabled === true
    ? 'bg-origami-sage-light-7'
    : style === 'primary'
      ? 'bg-origami-sage-light-12'
      : style === 'discouraged'
        ? 'bg-origami-sage-light-4'
        : 'bg-origami-grass-light-5'

  const fgClass = disabled === true
    ? 'text-origami-sage-light-11'
    : style === 'primary'
      ? 'text-white'
      : style === 'discouraged'
        ? 'text-origami-sage-light-11'
        : 'text-origami-grass-light-11'

  const fontClass = 'font-bold leading-[22px]'

  const layoutClass = 'px-4 py-[11px]'

  const shapeClass = 'rounded-2xl'

  const widthClass = fullWidth === true && 'w-full'

  const className = cx(
    'relative',
    bgClass,
    fgClass,
    fontClass,
    layoutClass,
    shapeClass,
    widthClass
  )

  return (
    <Button className={className} onClick={onClick} disabled={disabled}>
      {label}
      {
        chevron === true && (
          <div className='absolute pointer-events-none inset-y-4 right-4'>
            <img src={rightChevronImageUrl} className='relative -top-px -right-px' />
          </div>
        )
      }
    </Button>
  )
}
