// The toolbar at the top of the screen.
//
// Automatically hides away when the user is gesturing around the doc.

import { animated, FrameValue } from '@react-spring/web'
import React from 'react'

import IconButton from 'components/icon-button/IconButton'

export interface Props {
  onNext: () => void
  isHiddenFrameValue: FrameValue<boolean>
}

export default (props: Props): JSX.Element => {
  const { onNext, isHiddenFrameValue } = props

  return (
    <animated.div
      className='absolute cursor-auto top-4 right-4 h-0 transition'
      style={{
        opacity: isHiddenFrameValue.to(
          (isActive) => isActive ? 0 : 1
        ),
        y: isHiddenFrameValue.to(
          (isActive) => isActive ? -8 : 0
        )
      }}
    >
      <IconButton
        icon='right-arrow'
        style='primary'
        onClick={onNext}
      />
    </animated.div>
  )
}
