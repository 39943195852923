import cx from 'classnames'
import OrigamiButton from 'components/origami-button/OrigamiButton'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

export interface Props {
  initialText?: string
  className?: string
  autoFocus?: boolean
  placeholder?: string
  onDone: (value: string, size: { width: number, height: number }) => void
}

export default React.forwardRef((
  props: Props, ref: React.ForwardedRef<HTMLTextAreaElement>
): JSX.Element => {
  const { initialText, className, autoFocus, placeholder, onDone } = props
  const [value, setValue] = React.useState(initialText ?? '')
  const measureRef = React.useRef<HTMLDivElement>(null)

  function getSize (): { width: number, height: number } {
    return measureRef.current?.getBoundingClientRect() ?? { width: 0, height: 0 }
  }

  return (
    <div className='fixed inset-0 backdrop-blur'>
      <div
        ref={measureRef}
        className='absolute pointer-events-none text-transparent'
      >
        {value}
      </div>
      <div className='absolute inset-4'>
        <div
          className='absolute inset-0 flex flex-col justify-center'
        >
          <TextareaAutosize
            ref={ref}
            className={cx(
              'text-center w-full outline-none resize-none bg-transparent',
              className
            )}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                onDone(value, getSize())
              }
            }}
            placeholder={placeholder}
            autoFocus={autoFocus}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            onBlur={() => {
              onDone(value, getSize())
            }}
          />
        </div>
        <div className='absolute top-0 right-0'>
          <OrigamiButton
            label='Done'
            style='primary'
            onClick={() => {
              onDone(value, getSize())
            }}
          />
        </div>
      </div>
    </div>
  )
})
