import React from 'react'

import ImportOptionsCard from 'features/docimport/ImportOptionsCard'
import OrigamiButton from 'components/origami-button/OrigamiButton'
import { EXPERIMENTS, ExperimentVariant, getExperimentVariant } from 'features/analytics/google'

export interface Props {
  onBack: () => void
  onUpload: (file: File) => Promise<void>
}

export default (props: Props): JSX.Element => {
  const { onBack, onUpload } = props
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const experimentVariant = useExperimentVariant()

  switch (state.status) {
    case 'deciding': {
      return (
        <ImportOptionsCard
          onBack={onBack}
          onUpload={(file) => {
            dispatch({ name: 'upload-started' })
            onUpload(file)
              .catch((err) => {
                dispatch({ name: 'upload-failed' })
                console.log(err)
                throw err
              })
          }}
          experimentVariant={experimentVariant}
        />
      )
    }
    case 'uploading': {
      return (
        <div className='text-center font-mono italic font-[450]'>
          Preparing your doc...
        </div>
      )
    }
    case 'failed': {
      return (
        <div className='text-center space-y-6'>
          <p className='font-mono italic font-[450]'>Oops... something went wrong.</p>
          <OrigamiButton
            onClick={() => dispatch({ name: 'restart' })}
            label='← Try again'
          />
        </div>
      )
    }
  }
}

type State = Deciding | Uploading | Failed
interface Deciding {
  status: 'deciding'
}
interface Uploading {
  status: 'uploading'
}
interface Failed {
  status: 'failed'
}

type Action = UploadStarted | UploadFailed | Restart
interface UploadStarted {
  name: 'upload-started'
}
interface UploadFailed {
  name: 'upload-failed'
}
interface Restart {
  name: 'restart'
}

const initialState: State = { status: 'deciding' }

function reducer (state: State, action: Action): State {
  switch (action.name) {
    case 'upload-started': {
      return {
        status: 'uploading'
      }
    }
    case 'upload-failed': {
      return {
        status: 'failed'
      }
    }
    case 'restart': {
      return {
        status: 'deciding'
      }
    }
  }
}

export function useExperimentVariant (): ExperimentVariant<'Import Options'> {
  const [variant, setVariant] = React.useState<ExperimentVariant<'Import Options'>>(
    EXPERIMENTS['Import Options'].variants[0]
  )
  React.useEffect(
    () => {
      getExperimentVariant('Import Options')
        .then(setVariant)
        .catch((err) => {
          console.log(err)
          throw err
        })
    },
    []
  )
  return variant
}
