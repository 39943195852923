import React from 'react'
import { useNavigate, useRoutes } from 'react-router-dom'

import WhiteCard from 'components/white-card/WhiteCard'
import { ExperimentVariant } from 'features/analytics/google'

import ComingSoonMethod from './ComingSoonMethod'
import EmailMethod from './EmailMethod'
import GoogleDriveMethod from './GoogleDriveMethod'
import MainMenu, { ImportOptionProps } from './MainMenu'
import { ImportOption, IMPORT_OPTIONS } from './model'
import OtherMethod from './OtherMethod'
import UploadMethod from './UploadMethod'
import OrigamiButton from 'components/origami-button/OrigamiButton'

export interface Props {
  onBack: () => void
  onUpload: (file: File) => void
  experimentVariant: ExperimentVariant<'Import Options'>
}

export default (props: Props): JSX.Element => {
  const { onBack, onUpload, experimentVariant } = props

  const navigate = useNavigate()
  const options: ImportOptionProps[] = rotateOptions(experimentVariant)
    .map((option) => ({
      ...option,
      onClick: () => navigate(option.id)
    }))
  const handleBackToMainMenu = (): void => navigate('')

  const childRoutes = useRoutes([
    {
      index: true,
      element: (
        <MainMenu
          onBack={onBack}
          options={options}
        />
      )
    },
    {
      path: IMPORT_OPTIONS.GDRIVE.id,
      element: (
        <GoogleDriveMethod
          onBack={handleBackToMainMenu}
          onChoose={onUpload}
        />
      )
    },
    {
      path: IMPORT_OPTIONS.PICTURE.id,
      element: (
        <ComingSoonMethod
          label={IMPORT_OPTIONS.PICTURE.label}
          onBack={handleBackToMainMenu}
        />
      )
    },
    {
      path: IMPORT_OPTIONS.UPLOAD.id,
      element: (
        <UploadMethod
          onBack={handleBackToMainMenu}
          onUpload={onUpload}
        />
      )
    },
    {
      path: IMPORT_OPTIONS.OTHER.id,
      element: (
        <OtherMethod
          onBack={handleBackToMainMenu}
        />
      )
    },
    {
      path: IMPORT_OPTIONS.EMAIL.id,
      element: (
        <EmailMethod
          onBack={handleBackToMainMenu}
        />
      )
    }
  ])

  if (childRoutes === null) {
    return (
      <div className='space-y-4'>
        <p className='text-[450] italic font-mono'>
          We couldn't find what you're looking for.
        </p>
        <OrigamiButton
          label='Start over'
          onClick={handleBackToMainMenu}
        />
      </div>
    )
  }

  return (
    <WhiteCard>
      <div className='relative h-[316px]'>
        {childRoutes}
      </div>
    </WhiteCard>
  )
}

function rotateOptions (
  variant: ExperimentVariant<'Import Options'>
): ImportOption[] {
  switch (variant) {
    case 'choose_from_google_drive_first': {
      return [
        IMPORT_OPTIONS.GDRIVE,
        IMPORT_OPTIONS.PICTURE,
        IMPORT_OPTIONS.UPLOAD,
        IMPORT_OPTIONS.OTHER,
        IMPORT_OPTIONS.EMAIL
      ]
    }
    case 'take_a_picture_first': {
      return [
        IMPORT_OPTIONS.PICTURE,
        IMPORT_OPTIONS.UPLOAD,
        IMPORT_OPTIONS.GDRIVE,
        IMPORT_OPTIONS.OTHER,
        IMPORT_OPTIONS.EMAIL
      ]
    }
    case 'upload_a_pdf_file_first': {
      return [
        IMPORT_OPTIONS.UPLOAD,
        IMPORT_OPTIONS.GDRIVE,
        IMPORT_OPTIONS.PICTURE,
        IMPORT_OPTIONS.OTHER,
        IMPORT_OPTIONS.EMAIL
      ]
    }
  }
}
