import cx from 'classnames'
import fscreen from 'fscreen'
import React from 'react'

import OrigamiButton from 'components/origami-button/OrigamiButton'
import WhiteCard from 'components/white-card/WhiteCard'
import { blankDrawing, Drawing, isBlank } from 'features/draw/drawing'
import DrawingPad from 'features/draw/DrawingPad'
import { Signature } from 'features/signaturedb/types'

import Button from 'components/button/Button'
import enterFullScreenImgUrl from './enter-full-screen.svg'
import exitFullScreenImgUrl from './exit-full-screen.svg'
import { recordEvent } from 'features/analytics/google'

/**
 * blank   = untainted drawing pad.
 * started = at least one stroke started, but done button not clicked yet.
 */
export type SignatureState = 'blank' | 'started'

export interface InstructionCopy {
  title: React.ReactNode
  subtitle: React.ReactNode
}

export interface Props {
  signature: Drawing
  onSignatureChange: (drawing: Drawing, size: Signature['size']) => void
  instructionCopy?: InstructionCopy
  getMessageBelowSignatureLine: (state: 'blank' | 'started') => string
  doneLabel: string
  onDone: () => void
}

export default (props: Props): JSX.Element => {
  const {
    signature,
    onSignatureChange,
    instructionCopy,
    getMessageBelowSignatureLine,
    doneLabel,
    onDone
  } = props

  const isSignatureBlank = isBlank(signature)
  const padRef = React.useRef<HTMLDivElement>(null)

  return (
    <Container>
      <Pad isBlank={isSignatureBlank} ref={padRef}>
        <Background
          message={
            getMessageBelowSignatureLine(isSignatureBlank ? 'blank' : 'started')
          }
        />
        <DrawingPad
          className='absolute inset-0'
          penSize={3}
          inkColor='#3451b2'
          drawing={signature}
          onDrawingChange={
            (drawing, size) => onSignatureChange(drawing, { ...size, height: 260 })
          }
        />
        {
          instructionCopy !== undefined && (
            <Instructions
              {...instructionCopy}
            />
          )
        }
        <FullScreenControls
          onEnter={() => {
            if (padRef.current === null) {
              return
            }
            fscreen.requestFullscreen(padRef.current)
          }}
        />
        <Buttons
          disabled={isSignatureBlank}
          doneLabel={doneLabel}
          onClear={() => {
            recordEvent('clear_signature')
            onSignatureChange(blankDrawing(), { width: 0, height: 0 })
          }}
          onDone={() => {
            if (screen?.orientation != null) {
              screen.orientation.unlock()
            }
            if (fscreen.fullscreenEnabled && fscreen.fullscreenElement != null) {
              fscreen.exitFullscreen()
            }
            onDone()
          }}
        />
      </Pad>
    </Container>
  )
}

function Container ({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <div className='h-[316px]'>
      {children}
    </div>
  )
}

const Pad = React.forwardRef((
  { isBlank, children }: { isBlank: boolean, children: React.ReactNode },
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element => {
  return (
    <WhiteCard ref={ref}>
      <div
        className={cx(
          'relative',
          'cursor-crosshair',
          'transition-[height] overflow-hidden',
          isBlank ? 'h-[260px]' : 'h-[316px]'
        )}
      >
        {children}
      </div>
    </WhiteCard>
  )
})

function Background ({ message }: { message: string }): JSX.Element {
  return (
    <div className='px-2 absolute top-[211px] inset-x-0'>
      <SignatureLine />
      <Message>{message}</Message>
    </div>
  )
}

function SignatureLine (): JSX.Element {
  return (
    <div className='h-px bg-origami-grass-light-6' />
  )
}

function Message ({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <div
      className={cx(
        'text-center text-origami-grass-light-10 font-mono italic text-xs',
        'font-[450] leading-[16px] py-4'
      )}
    >
      {children}
    </div>
  )
}

function Instructions (
  props: { title: React.ReactNode, subtitle: React.ReactNode, relaxedLayout?: boolean }
): JSX.Element {
  const { title, subtitle, relaxedLayout } = props

  return (
    <div
      className={cx(
        'absolute top-4 text-center inset-x-10',
        relaxedLayout === true ? 'space-y-5' : 'space-y-2'
      )}
    >
      <div className='font-mono font-bold leading-[1.5] text-origami-grass-light-12'>
        {title}
      </div>
      <div className='text-xs tracking-[1.25] text-origami-grass-light-11'>
        {subtitle}
      </div>
    </div>
  )
}

function FullScreenControls ({ onEnter }: { onEnter: () => void }): JSX.Element | null {
  const [isFullScreen, setFullScreen] = React.useState(false)

  if (!fscreen.fullscreenEnabled) {
    return null
  }

  return (
    <div className='absolute top-2 right-2'>
      <Button
        className='rounded-full w-10 h-10 flex items-center justify-center'
        onClick={() => {
          if (isFullScreen) {
            if (screen?.orientation != null) {
              screen.orientation.unlock()
            }
            setFullScreen(false)
            fscreen.exitFullscreen()
          } else {
            setFullScreen(true)
            onEnter()
            if (screen?.orientation != null) {
              screen
                .orientation
                .lock('landscape')
                .catch((err) => {
                  console.log(err)
                })
            }
          }
        }}
      >
        <img
          src={isFullScreen ? exitFullScreenImgUrl : enterFullScreenImgUrl}
          width={24}
          height={24}
        />
      </Button>
    </div>
  )
}

function Buttons (
  {
    disabled,
    doneLabel,
    onClear,
    onDone
  }: {
    disabled: boolean
    doneLabel: string
    onClear: () => void
    onDone: () => void
  }
): JSX.Element {
  return (
    <div
      className={cx(
        'absolute inset-x-2 top-[264px] transition',
        disabled ? 'opacity-0' : 'opacity-100',
        'flex space-x-1'
      )}
    >
      <OrigamiButton label='Clear' onClick={onClear} disabled={disabled} />
      <OrigamiButton
        label={doneLabel}
        style='primary'
        fullWidth
        onClick={() => onDone()}
        disabled={disabled}
      />
    </div>
  )
}
