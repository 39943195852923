import { PDFDocumentProxy } from 'pdfjs-dist'
import { loadPdf } from './pdfjs'

export class Pdf {
  readonly pdfjsDoc: PDFDocumentProxy

  constructor (pdfjsDoc: PDFDocumentProxy) {
    this.pdfjsDoc = pdfjsDoc
  }
}

export async function loadPdfFromBytes (bytes: Uint8Array): Promise<Pdf> {
  return new Pdf(await loadPdf(bytes))
}
