import load from 'load-script'
import { isLiveChannel, isProduction, isStorybook } from 'util/env'

export function initializeGoogleOptimize (): void {
  if (isProduction() && !isStorybook() && isLiveChannel()) {
    const containerId = 'OPT-K6T9TB6'
    const url = `https://www.googleoptimize.com/optimize.js?id=${containerId}`
    load(url, { async: true }, (err) => {
      if (err !== null) {
        console.log(err)
        throw err
      }
    })
  }
}
