import { Gesture } from 'features/gesture/gesture'
import GestureCanvas from 'features/gesture/GestureCanvas'
import { Signature } from 'features/signaturedb/types'
import { head, tail } from 'lodash'
import React, { HTMLProps } from 'react'
import {
  addPath, Drawing, extendPath, getLastPath, Point, replaceLastPath
} from './drawing'
import DrawingView from './DrawingView'

export interface Props extends HTMLProps<HTMLDivElement> {
  inkColor: string
  penSize: number
  drawing: Drawing
  onDrawingChange: (drawing: Drawing, size: Signature['size']) => void
}

export default (props: Props): JSX.Element => {
  const { inkColor, penSize, drawing, onDrawingChange, ...rest } = props
  const ref = React.useRef<HTMLDivElement>(null)
  const isDrawingUpdateScheduled = React.useRef(false)
  const gestureQueue = React.useRef<Gesture[]>([])
  const penStart = React.useRef<Point | null>(null)

  const updateDrawing = React.useCallback(
    () => {
      isDrawingUpdateScheduled.current = false
      let points: Point[] = []
      const paths: Point[][] = [points]
      const isPathContinued = penStart.current != null
      gestureQueue.current.forEach((gesture) => {
        switch (gesture.type) {
          case 'start': {
            penStart.current = { x: gesture.x, y: gesture.y }
            break
          }
          case 'stop': {
            penStart.current = null
            points = []
            paths.push(points)
            break
          }
          case 'translate': {
            if (penStart.current !== null) {
              points.push({
                x: penStart.current.x + gesture.x,
                y: penStart.current.y + gesture.y
              })
            }
            break
          }
        }
      })
      gestureQueue.current = []
      let updatedDrawing = drawing
      const firstPath = head(paths)
      const tailPaths = tail(paths)
      if (isPathContinued && firstPath !== undefined) {
        updatedDrawing = replaceLastPath(
          updatedDrawing,
          extendPath(getLastPath(drawing), ...firstPath)
        )
      } else if (firstPath !== undefined) {
        updatedDrawing = addPath(updatedDrawing, { points: firstPath })
      }
      tailPaths
        .filter((points) => points.length > 0)
        .forEach((points) => {
          updatedDrawing = addPath(updatedDrawing, { points })
        })
      onDrawingChange(updatedDrawing, {
        width: ref.current?.clientWidth ?? 0,
        height: ref.current?.clientHeight ?? 0
      })
    },
    [drawing]
  )

  const handleGesture = (gesture: Gesture): void => {
    gestureQueue.current.push(gesture)
    if (!isDrawingUpdateScheduled.current) {
      isDrawingUpdateScheduled.current = true
      requestAnimationFrame(updateDrawing)
    }
  }

  return (
    <div {...rest} ref={ref}>
      <GestureCanvas
        className='absolute inset-0'
        onGesture={handleGesture}
      >
        <DrawingView
          drawing={drawing}
          inkColor={inkColor}
          penSize={penSize}
          pointSamplingRate={3}
          smoothingFactor={0.2}
        />
      </GestureCanvas>
    </div>
  )
}
