import { LineCapStyle, PDFDocument, PDFFont, rgb } from 'pdf-lib'

import { toSvgPathDefinition } from 'features/draw/drawing'
import { Signature } from 'features/signaturedb/types'

import { loadPdfFromBytes, Pdf } from './pdf'

export type Change = AddSignatureChange | AddTextChange | AddWatermarkChange

export interface AddSignatureChange {
  type: 'add-signature'

  location: {
    // Page number starts with 1, not 0.
    pageNumber: number
    x: number
    y: number
    scale: number
  }

  signature: Signature
}

export interface AddTextChange {
  type: 'add-text'

  location: {
    // Page number starts with 1, not 0.
    pageNumber: number
    x: number
    y: number
    scale: number
  }

  text: string
}

export interface AddWatermarkChange {
  type: 'add-watermark'
}

export async function applyChangesToPdf (pdf: Pdf, changes: Change[]): Promise<Pdf> {
  // Get the bytes from the PDF.
  const bytes = await pdf.pdfjsDoc.getData()

  // Use PDF-LIB to make the changes.
  const changedBytes = await applyChangesToPdfBytes(bytes, changes)

  // Create a PDF from the updated bytes.
  return await loadPdfFromBytes(changedBytes)
}

async function applyChangesToPdfBytes (
  pdfBytes: Uint8Array, changes: Change[]
): Promise<Uint8Array> {
  // Load it as a PDF-LIB doc.
  const pdfDoc = await PDFDocument.load(pdfBytes)

  // Apply changes to the doc in place.
  await applyChangesToPdfLibDocInPlace(pdfDoc, changes)

  // Return the updated bytes.
  return await pdfDoc.save()
}

async function applyChangesToPdfLibDocInPlace (
  pdfLibDoc: PDFDocument, changes: Change[]
): Promise<void> {
  await Promise.all(
    changes.map(async (change) => await applyChangeToPdfLibDocInPlace(pdfLibDoc, change))
  )
}

async function applyChangeToPdfLibDocInPlace (
  pdfLibDoc: PDFDocument, change: Change
): Promise<void> {
  const { default: fontkit } = await import('@pdf-lib/fontkit')
  pdfLibDoc.registerFontkit(fontkit)

  const { default: fontUrl } = await import('./Inter-Bold.otf')
  const response = await fetch(fontUrl)
  const font = await pdfLibDoc.embedFont(await response.arrayBuffer())

  switch (change.type) {
    case 'add-signature': {
      await applyAddSignatureChangeToPdfLibDocInPlace(pdfLibDoc, change)
      return
    }
    case 'add-text': {
      await applyAddTextChangeToPdfLibDocInPlace(pdfLibDoc, font, change)
      return
    }
    case 'add-watermark': {
      await applyAddWatermarkChangeToPdfLibDocInPlace(pdfLibDoc, change)
      return
    }
    default: {
      const exhaustiveCheck: never = change
      throw new Error(`Changes not all handled: ${JSON.stringify(exhaustiveCheck)}`)
    }
  }
}

async function applyAddSignatureChangeToPdfLibDocInPlace (
  pdfLibDoc: PDFDocument, change: AddSignatureChange
): Promise<void> {
  const { location, signature } = change
  const page = pdfLibDoc.getPage(location.pageNumber - 1)
  page.drawSvgPath(
    toSvgPathDefinition(signature.drawing),
    {
      x: location.x,
      y: page.getHeight() - location.y, // Note: y-axis is flipped in PDF coordinates.
      scale: location.scale,
      borderColor: rgb(52 / 255, 81 / 255, 178 / 255),
      borderWidth: 3,
      borderLineCap: LineCapStyle.Round
    }
  )
}

async function applyAddTextChangeToPdfLibDocInPlace (
  pdfLibDoc: PDFDocument, font: PDFFont, change: AddTextChange
): Promise<void> {
  const { location, text } = change
  const page = pdfLibDoc.getPage(location.pageNumber - 1)
  const textSize = location.scale * 18
  page.drawText(
    text,
    {
      x: location.x,
      // Note: y-axis is flipped in PDF coordinates.
      // Note: I don't know where this 0.95 is coming from, but it works.
      y: page.getHeight() - location.y - textSize * 0.95,
      font,
      size: textSize,
      lineHeight: location.scale * 22
    }
  )
}

async function applyAddWatermarkChangeToPdfLibDocInPlace (
  pdfLibDoc: PDFDocument, change: AddWatermarkChange
): Promise<void> {
  // The ratio of pt to px, which helps us convert the design (px = 96 dpi) to
  // the PDF coordinates (pt = 72 dpi).
  const pxRatio = 72 / 96
  const { default: fontUrl } = await import('./Inter-Regular.otf')
  const font = await pdfLibDoc.embedFont(await (await fetch(fontUrl)).arrayBuffer())
  const { default: imageUrl } = await import('./roger.png')
  const logoImage = await pdfLibDoc.embedPng(await (await fetch(imageUrl)).arrayBuffer())

  const text = 'Signed using thanksroger.com because it was too complicated any other way.'
  const ribbonHeight = 24 * pxRatio
  const ribbonPaddingY = 6 * pxRatio
  const fontSize = 9 * pxRatio
  const lineHeight = 12 * pxRatio
  const textWidth = font.widthOfTextAtSize(text, fontSize)
  const imageWidth = 62 * pxRatio
  const imageHeight = 10 * pxRatio
  const spacing = 8 * pxRatio
  const contentWidth = textWidth + spacing + imageWidth

  const page = pdfLibDoc.getPage(0)

  page.drawRectangle({
    x: 0,
    y: page.getHeight() - ribbonHeight,
    width: page.getWidth(),
    height: ribbonHeight,
    color: rgb(248 / 255, 250 / 255, 249 / 255)
  })
  page.drawText(text, {
    size: fontSize,
    font,
    x: (page.getWidth() - contentWidth) / 2 + imageWidth + spacing,
    y: page.getHeight() - ribbonHeight + ribbonPaddingY + (lineHeight - fontSize)
  })
  page.drawImage(logoImage, {
    x: (page.getWidth() - contentWidth) / 2,
    y: page.getHeight() - (ribbonHeight - (ribbonHeight - imageHeight) / 2),
    width: imageWidth,
    height: imageHeight
  })
}
