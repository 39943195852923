import cx from 'classnames'
import React from 'react'

import OrigamiButton from 'components/origami-button/OrigamiButton'

export default (): JSX.Element => {
  const ref = React.useRef<HTMLInputElement>(null)
  const [isCopied, setCopied] = React.useState(false)

  function handleClick (): void {
    if (ref.current === null) {
      return
    }
    ref.current.select()
    document.execCommand('copy')
    setCopied(true)
  }

  return (
    <>
      <input
        ref={ref}
        className={cx(
          'absolute outline-none pointer-events-none',
          'bg-transparent text-transparent h-px w-px'
        )}
        tabIndex={-1}
        readOnly
        value='free@docs.thanksroger.com'
      />
      <OrigamiButton
        label={isCopied ? 'Copied!' : 'Copy email address'}
        disabled={isCopied}
        style='primary'
        fullWidth
        onClick={handleClick}
      />
    </>
  )
}
