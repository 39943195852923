import cx from 'classnames'
import React from 'react'

import deleteIconUrl from './delete.svg'
import { useDrag } from '@use-gesture/react'

export interface Props {
  onDragEnter: (...args: any[]) => void
  onDragExit: () => void
  onDragDrop: () => void
}

export default (props: Props): JSX.Element => {
  const { onDragEnter, onDragExit, onDragDrop } = props
  const ref = React.useRef<HTMLDivElement>(null)
  const isTargeted = React.useRef(false)

  React.useEffect(
    () => {
      const preventDefault = (e: Event): void => {
        e.preventDefault()
      }
      document.addEventListener('gesturestart', preventDefault)
      document.addEventListener('gesturechange', preventDefault)
      document.body.style.touchAction = 'none'
      document.body.style.height = '100%'
      return () => {
        document.removeEventListener('gesturestart', preventDefault)
        document.removeEventListener('gesturechange', preventDefault)
      }
    },
    []
  )

  useDrag(
    ({ xy: [x, y], down }) => {
      const target = document.elementFromPoint(x, y)
      if (!down && target === ref.current) {
        onDragDrop()
        onDragExit()
        return
      }
      if (down && target === ref.current) {
        if (!isTargeted.current) {
          onDragEnter()
        }
        isTargeted.current = true
      } else {
        if (isTargeted.current) {
          onDragExit()
        }
        isTargeted.current = false
      }
    },
    {
      target: document.body
    }
  )

  return (
    <div
      ref={ref}
      className={cx(
        'bg-origami-tomato-light-11 rounded-full flex justify-center items-center w-11 h-11'
      )}
      data-id='me'
    >
      <img src={deleteIconUrl} className='pointer-events-none' />
    </div>
  )
}
