import React from 'react'
import { chunk, sumBy } from 'lodash'

import { Drawing, Path } from './drawing'
import { smooth, toSvgPathDefinition } from 'features/smoothing/smoothing'

export interface Props {
  // Hex color for the stroke.
  inkColor: string

  // Width in pixels.
  penSize: number

  drawing: Drawing

  // Smoothing.
  pointSamplingRate: number
  smoothingFactor: number
}

/**
 * Displays a drawing as an SVG image.
 */
export default (props: Props): JSX.Element => {
  const { inkColor, penSize, drawing, pointSamplingRate, smoothingFactor } = props

  const sampledPaths: Path[] = drawing.paths.map((path) => ({
    points: chunk(path.points, pointSamplingRate)
      .map((points) => ({
        x: sumBy(points, 'x') / points.length,
        y: sumBy(points, 'y') / points.length
      }))
  }))
  const smoothedPaths = sampledPaths.map((path) => smooth(path, { factor: smoothingFactor }))

  return (
    <svg width='100%' height='100%'>
      <path
        d={smoothedPaths.map(toSvgPathDefinition).join(' ')}
        strokeWidth={penSize}
        stroke={inkColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='transparent'
      />
    </svg>
  )
}
