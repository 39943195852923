import { Drawing, isDrawing } from 'features/draw/drawing'
import { isNumber } from 'lodash'

export type Signature = DrawnSignature

export function isSignature (obj: any): obj is Signature {
  return isDrawnSignature(obj)
}

export type SignatureType = DrawnSignature['type']

export interface DrawnSignature {
  type: 'drawn'

  size: {
    width: number
    height: number
  }

  drawing: Drawing
}

export function isDrawnSignature (obj: any): obj is DrawnSignature {
  return obj?.type === 'drawn' &&
    isNumber(obj?.size?.width) &&
    isNumber(obj?.size?.height) &&
    isDrawing(obj?.drawing)
}
