import cx from 'classnames'
import React from 'react'

import OrigamiButton from 'components/origami-button/OrigamiButton'
import BackButton from 'components/white-card/BackButton'

import { ImportOptionLabel } from './model'

export interface Props {
  onBack: () => void
  options: ImportOptionProps[]
}

export default (props: Props): JSX.Element => {
  const { onBack, options } = props

  return (
    <>
      <div className='absolute top-2 left-2'>
        <BackButton
          onClick={onBack}
        />
      </div>
      <div
        className={cx(
          'py-4 px-10 text-center font-bold font-mono text-grass-light-12 leading-[1.5]',
          'whitespace-nowrap'
        )}
      >
        Import your PDF doc
      </div>
      <div className='px-2 space-y-2 pb-2'>
        {
          options.map((option, i) => (
            <ImportOption key={i} {...option} />
          ))
        }
      </div>
    </>
  )
}

export interface ImportOptionProps {
  label: ImportOptionLabel
  primary?: boolean
  onClick?: () => void
}

function ImportOption (props: ImportOptionProps): JSX.Element {
  const { label, primary, onClick } = props

  return (
    <OrigamiButton
      label={label}
      fullWidth
      style={primary === true ? 'primary' : 'discouraged'}
      onClick={onClick}
      chevron
    />
  )
}
