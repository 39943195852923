import { Path, Point } from 'features/draw/drawing'
import { addVectorToPoint, scaleVector, vectorDifference } from './math'

export interface SmoothedPath {
  curves: CubicBezier[]
}

export interface CubicBezier {
  // Start point of the curve.
  start: Point

  // Start control point.
  startControl: Point

  // End point of the curve.
  end: Point

  // End control point.
  endControl: Point
}

export interface SmoothingOptions {
  // Smoothing factor (greater number is more smooth).
  //
  // 0 results in no smoothing.
  //
  // This number is a scalar multiplier applied to the line between
  // the point before and after the point on a path being smoothed.
  //
  // The result affects the distance from the point being smoothed to
  // its control points.
  factor: number
}

const DEFAULT_SMOOTHING_FACTOR = 0.2

export function smooth (path: Path, opts?: SmoothingOptions): SmoothedPath {
  const factor = opts?.factor ?? DEFAULT_SMOOTHING_FACTOR

  if (path.points.length === 0) {
    return {
      curves: []
    }
  }

  if (path.points.length === 1) {
    const point = path.points[0]
    return {
      curves: [{
        start: point,
        startControl: point,
        end: point,
        endControl: point
      }]
    }
  }

  return {
    curves: path.points
      .map((_, i) => getPointContext(path.points, i))
      .reduce<Array<[PointContext, PointContext]>>(
      (pairs, start, i, arr) => {
        if (i === arr.length - 1) {
          return pairs
        }
        const end = arr[i + 1]
        return [...pairs, [start, end]]
      },
      []
    )
      .map(([start, end]) => createCurve(start, end, factor))
  }
}

export type PointContext = [Point, Point, Point]

export function getPointContext (points: Point[], index: number): PointContext {
  const previous = points[Math.max(0, index - 1)]
  const current = points[index]
  const next = points[Math.min(points.length - 1, index + 1)]
  return [previous, current, next]
}

export function createCurve (start: PointContext, end: PointContext, factor: number): CubicBezier {
  const [startPrev, startCurrent, startNext] = start
  const [endPrev, endCurrent, endNext] = end

  const startControlVector = scaleVector(factor, vectorDifference(startPrev, startNext))
  const endControlVector = scaleVector(factor, vectorDifference(endNext, endPrev))

  return {
    start: startCurrent,
    startControl: addVectorToPoint(startCurrent, startControlVector),
    end: endCurrent,
    endControl: addVectorToPoint(endCurrent, endControlVector)
  }
}

export function toSvgPathDefinition (path: SmoothedPath): string {
  const firstCurve = path.curves[0]
  if (firstCurve === undefined) {
    return ''
  }

  return `M ${firstCurve.start.x} ${firstCurve.start.y} ` +
    path.curves.map(toSvgPathDef).join(' ')
}

export function toSvgPathDef (curve: CubicBezier): string {
  const sc = toSvgPointDef(curve.startControl)
  const ec = toSvgPointDef(curve.endControl)
  const e = toSvgPointDef(curve.end)
  return `C ${sc}, ${ec}, ${e}`
}

export function toSvgPointDef (point: Point): string {
  return `${point.x} ${point.y}`
}
