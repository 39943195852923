import cx from 'classnames'
import React from 'react'

import OrigamiButton from 'components/origami-button/OrigamiButton'
import BackButton from 'components/white-card/BackButton'
import { showPicker } from 'features/googlepicker'

export interface Props {
  onBack: () => void
  onChoose: (file: File) => void
}

export default (props: Props): JSX.Element => {
  const { onBack, onChoose } = props

  return (
    <div className='relative flex flex-col h-full justify-between'>
      <div className='absolute top-2 left-2'>
        <BackButton onClick={onBack} />
      </div>
      <div className='space-y-2'>
        <div
          className={cx(
            'py-4 px-10 text-center font-bold font-mono text-grass-light-12 leading-[1.5]',
            'whitespace-nowrap'
          )}
        >
          Choose from Google Drive
        </div>
        <div
          className={cx(
            'italic font-mono font-[450] tracking-[1.3] text-origami-sage-light-11 px-4'
          )}
        >
          You may choose a PDF file stored in your Google Drive.
        </div>
      </div>
      <div className='p-2'>
        <OrigamiButton
          label='Choose from Google Drive'
          style='primary'
          fullWidth
          onClick={() => {
            showPicker()
              .then(onChoose)
              .catch((err) => {
                // TODO: Handle 404s, which are probably permission problems.
                // We can just tell the user to try another file in that case.
                console.log(err)
                throw err
              })
          }}
        />
      </div>
    </div>
  )
}
