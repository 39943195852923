import cx from 'classnames'
import React from 'react'

export interface Props {
  value: string
  onChange: (value: string) => void
}

export default (props: Props): JSX.Element => {
  const { value, onChange } = props

  return (
    <input
      value={value}
      onChange={(event) => onChange(event.target.value)}
      className={cx(
        'leading-[22px] font-mono italic py-[9px] text-origami-sage-light-12 font-[450]',
        'border-b border-origami-grass-light-6',
        'placeholder:text-origami-sage-light-11',
        'outline-none w-full'
      )}
      placeholder='Type your answer...'
    />
  )
}
