import cx from 'classnames'
import React from 'react'

import BackButton from 'components/white-card/BackButton'
import OrigamiButton from 'components/origami-button/OrigamiButton'
import WhiteCard from 'components/white-card/WhiteCard'
import { recordEvent } from 'features/analytics/google'

export interface Props {
  onBack: () => void
  onChoose: (type: 'pdf') => void
}

export default (props: Props): JSX.Element => {
  const { onBack, onChoose } = props

  const [isNotAvailable, setNotAvailable] = React.useState(false)

  return (
    <WhiteCard>
      <div className='relative h-[316px]'>
        <div className='flex flex-col justify-between h-full'>
          <div className='absolute top-2 left-2'>
            <BackButton
              onClick={onBack}
            />
          </div>
          <div
            className={cx(
              'py-4 px-10 text-center font-bold font-mono text-grass-light-12 leading-[1.5]',
              'whitespace-nowrap'
            )}
          >
            What type of document?
          </div>
          <div className='px-2 space-y-2 pb-2'>
            <OrigamiButton
              style='discouraged'
              label={
                isNotAvailable
                  ? 'Coming soon...'
                  : 'Import a Word doc'
              }
              onClick={() => {
                recordEvent('attempt_word_doc_import')
                setNotAvailable(true)
              }}
              disabled={isNotAvailable}
              fullWidth
            />
            <OrigamiButton
              style='primary'
              label='Import a PDF doc'
              onClick={() => onChoose('pdf')}
              fullWidth
            />
          </div>
        </div>
      </div>
    </WhiteCard>
  )
}
