import * as Dialog from '@radix-ui/react-dialog'
import cx from 'classnames'
import React from 'react'

import PinchToZoomTip from 'features/tips/PinchToZoomTip'

export interface Props {
  open: Dialog.DialogProps['open']
  onOpenChange: Dialog.DialogProps['onOpenChange']
}

export default (props: Props): JSX.Element => {
  const { open, onOpenChange } = props

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} modal={false}>
      <Dialog.Portal>
        <Dialog.Content
          className={cx(
            'outline-none fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
            'pointer-events-none'
          )}
        >
          <PinchToZoomTip />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
