import { IsomorphicFirestore } from 'features/persistence/firestore'
import { isNumber, isString, isUndefined } from 'lodash'

export const DOC_PATH = 'sessions/{sessionId}'

export type TransientSessionDoc = TransientEmailedSessionDoc | TransientUploadedSessionDoc

export function isTransientSessionDoc (obj: any): obj is TransientSessionDoc {
  return isTransientEmailedSessionDoc(obj) || isTransientUploadedSessionDoc(obj)
}

export interface TransientUploadedSessionDoc {
  type: 'uploaded'
  createdTimestamp: number
  name: string
  mimeType: string
  storageLocation: {
    bucketName: string
    objectName: string
  }
}

export function isTransientUploadedSessionDoc (obj: any): obj is TransientUploadedSessionDoc {
  return obj?.type === 'uploaded' &&
    isNumber(obj?.createdTimestamp) &&
    isString(obj?.name) &&
    isString(obj?.mimeType) &&
    isString(obj?.storageLocation?.bucketName) &&
    isString(obj?.storageLocation?.objectName)
}

export interface TransientEmailedSessionDoc {
  type?: 'emailed'
  createdTimestamp: number
  from: string
  name: string
  mimeType: string
  storageLocation: {
    bucketName: string
    objectName: string
  }
}

export function isTransientEmailedSessionDoc (obj: any): obj is TransientEmailedSessionDoc {
  return (isUndefined(obj?.type) || obj?.type === 'emailed') &&
    isNumber(obj?.createdTimestamp) &&
    isString(obj?.from) &&
    isString(obj?.name) &&
    isString(obj?.mimeType) &&
    isString(obj?.storageLocation?.bucketName) &&
    isString(obj?.storageLocation?.objectName)
}

export type SessionDoc = EmailedSessionDoc | UploadedSessionDoc

export function isSessionDoc (obj: any): obj is SessionDoc {
  return isEmailedSessionDoc(obj) || isUploadedSessionDoc(obj)
}

export interface UploadedSessionDoc extends TransientUploadedSessionDoc {
  sessionId: string
}

export function isUploadedSessionDoc (obj: any): obj is UploadedSessionDoc {
  return isString(obj?.sessionId) && isTransientUploadedSessionDoc(obj)
}

export interface EmailedSessionDoc extends TransientEmailedSessionDoc {
  sessionId: string
}

export function isEmailedSessionDoc (obj: any): obj is EmailedSessionDoc {
  return isString(obj?.sessionId) && isTransientEmailedSessionDoc(obj)
}

export function generateSessionId (db: IsomorphicFirestore<any>): string {
  return db.generateId('sessions')
}

export async function saveSessionDoc (
  db: IsomorphicFirestore<any>, sessionId: string, sessionDoc: TransientSessionDoc
): Promise<SessionDoc> {
  const doc: SessionDoc = {
    ...sessionDoc,
    sessionId
  }
  await db.set(`sessions/${sessionId}`, doc)
  return doc
}

export async function getSessionDoc (
  db: IsomorphicFirestore<SessionDoc>, sessionId: string
): Promise<SessionDoc | null> {
  return await db.get(`sessions/${sessionId}`, isSessionDoc)
}
